<template>
  <div class="xbk-room">
    <div class="header">
      <div class="h-title">{{ lessonDetail.name }}</div>
      <div class="h-right-btn">
        <span class="t-time">{{ classTime }}</span>
        <!--        <span class="h-share"></span>
        <span class="h-info"></span>
        <span class="h-signal"></span>
        <span class="h-max"></span>
        <span class="h-mini"></span>
        <span class="h-close"></span> -->
      </div>
    </div>
    <div class="courseware" :style="'margin-left:' + (openThumb ? '0' : '-155') + 'px'">
      <!--  <div
        v-if="thumbUrls.length == 0"
        style="text-align: center; padding-top: 10px;padding-bottom: 10px; color: gray;"
      >
        此文件不支持缩略图
      </div> -->
      <div class="c-item" v-for="(item, index) in thumbUrls" :key="index">
        <div class="c-wrap" @click="onThumbClick(index)">
          <span>{{ index + 1 }}</span>
          <img :class="{ 'c-current': curThumb == index }" :src="item" alt="" />
        </div>
      </div>
      <div class="button-wrap">
        <div :class="openThumb ? 'open' : 'close'" @click="toggleThumb"></div>
      </div>
    </div>
    <div class="board-content">
      <el-tabs
        class="board-tab"
        size="mini"
        v-model="currentFileId"
        closable
        type="card"
        @tab-remove="onDeleteFileById"
        @tab-click="onClickBoardTab"
      >
        <el-tab-pane
          class="tab-item"
          size="mini"
          :key="file.fid"
          v-for="file in fileInfoList"
          :label="file.title"
          :name="file.fid"
        ></el-tab-pane>
      </el-tabs>
      <div
        id="paint_box"
        :style="'width:' + (openThumb ? 'calc(100vw - 376px)' : 'calc(100vw - 221px)')"
      ></div>
      <div class="board-button">
        <div class="turn-page">
          <span class="t-left" @click="prevStep"></span>
          <span class="t-text">{{ boardData.current }} / {{ boardData.total }}</span>
          <span class="t-right" @click="nextStep"></span>
          <span class="t-add" @click="addBoard"></span>
        </div>
        <div class="live-btn">
          <div v-if="cameraState == 0 && cameraEnable" class="camera-close" @click="toggleCamera">
            <span class="icon"></span>
            <span>开启摄像头</span>
          </div>
          <div v-if="cameraState == 1 && cameraEnable" class="camera-open" @click="toggleCamera">
            <span class="icon"></span>
            <span>关闭摄像头</span>
          </div>
          <!--  <div v-show="cameraState == 2" class="camera-close" @click="toggleCamera">
            <span class="icon"></span>
            <span>开启摄像头</span>
          </div> -->
          <div v-if="micState == 0 && micEnable" class="microphone" @click="toggleMic">
            <span class="icon-mute"></span>
            <span>开启麦克风</span>
          </div>
          <div v-if="micState == 1 && micEnable" class="microphone" @click="toggleMic">
            <span class="icon"></span>
            <span>关闭麦克风</span>
          </div>
          <!--          <div class="microphone" @click="toggleMic" v-if="micEnable">
            <span :class="enableMic ? 'icon' : 'icon-mute'"></span>
            <span>{{ enableMic ? '开启' : '关闭' }}麦克风</span>
          </div> -->
          <div class="microbtn-wrap" v-if="micEnable">
            <el-dropdown trigger="click" class="i-download" placement="top">
              <div
                :class="curLive == -1 ? 'micro-btn-active' : 'micro-btn'"
                @click="clickMicrop(-1)"
              >
                <span class="icon"></span>
              </div>
              <el-dropdown-menu slot="dropdown" class="micro-dropdown">
                <div class="text" v-if="role == 0" @click="muteAll">全体静音</div>
                <div class="text" @click="toggleMic">
                  {{ micState ? '关闭' : '开启' }}自己的麦克风
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div :class="curLive == 0 ? 'question-active' : 'question'" @click="clickQuestion(0)">
            <span class="icon"></span>
            <span>课堂答疑</span>
            <span class="dot" v-show="isStuAskQues"></span>
          </div>
          <div :class="curLive == 1 ? 'answer-active' : 'answer'" @click="clickAnswer(1)">
            <span class="icon"></span>
            <span>答题器</span>
          </div>
          <div class="ask-wrap" v-if="role == 0">
            <el-dropdown trigger="click" class="i-download" placement="top">
              <div :class="curLive == 2 ? 'ask-active' : 'ask'" @click="clickAsk(2)">
                <span class="icon"></span>
                <span>举手</span>
                <span class="dot" v-show="handsUpList.length > 0">{{ handsUpList.length }}</span>
              </div>
              <el-dropdown-menu slot="dropdown" class="ask-dropdown">
                <div class="l-header">举手学生（{{ handsUpList.length }}）</div>
                <div class="i-list">
                  <div class="i-item" v-for="(item, index) in handsUpList" :key="index">
                    <span class="i-name">{{ item.nickName }}</span>
                    <div class="right">
                      <div
                        v-if="item.action == 0"
                        class="phone"
                        @click="onStage(item, index)"
                      ></div>
                      <div
                        v-if="item.action == 1"
                        class="leave"
                        @click="downStage(item, 1, index)"
                      ></div>
                      <!-- <div class="leave" @click="leaveRoom(item)"></div> -->
                    </div>
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div v-else :class="curLive == 2 ? 'ask-active' : 'ask'" @click="stuClickAsk(2)">
            <span class="icon"></span>
            <span>举手</span>
          </div>
          <div :class="curLive == 3 ? 'chat-active' : 'chat'" @click="onShowChat(3)">
            <span class="icon"></span>
            <span>聊天</span>
            <span class="dot" v-show="hasNewMsg"></span>
          </div>
          <!--         <div :class="curLive == 4 ? 'record-active' : 'record'" @click="clickRecord(4)">
            <span class="icon"></span>
            <span>录制</span>
          </div> -->
          <div :class="curLive == 5 ? 'ware-active' : 'ware'" @click="onShowWare(5)">
            <span class="icon"></span>
            <span>课件</span>
          </div>
          <!--        <div :class="curLive == 6 ? 'setting-active' : 'setting'" @click="clickSetting(6)">
            <span class="icon"></span>
            <span>设置</span>
          </div> -->
        </div>
        <div class="line"></div>
        <div class="board-btn" v-show="boardEnable">
          <div :class="curTool == 0 ? 'choice-active' : 'choice'" @click="setToolType(0)">
            <span class="icon"></span>
            <span>选择</span>
          </div>
          <div :class="curTool == 3 ? 'laser-pen-active' : 'laser-pen'" @click="setToolType(3)">
            <span class="icon"></span>
            <span>激光笔</span>
          </div>
          <div class="pen-wrap">
            <el-dropdown trigger="click" class="i-download" placement="top">
              <div :class="curTool == 1 ||  curTool == 4? 'pen-active' : 'pen'">
                <span class="icon"></span>
                <span>画笔</span>
              </div>
              <el-dropdown-menu slot="dropdown" class="dropdown-content">
                <div class="i-style">
                  <span
                    :class="['pen-curve', { 'pen-curve-active': curPen == 1 }]"
                    @click="setToolType(1)"
                  ></span>
                  <span
                    :class="['line', { 'line-active': curPen == 4 }]"
                    @click="setToolType(4)"
                  ></span>
                </div>
                <div class="i-style">
                  <el-slider class="slider" @change="onSetThin" v-model="penThick"></el-slider>
                </div>
                <div class="i-style">
                  <span
                    v-for="(i, index) in 9"
                    :key="index"
                    :class="['color-block', { 'current-color': index === curColor }]"
                    @click="onSetBrushColor(index)"
                  ></span>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="text-wrap">
            <el-dropdown trigger="click" class="i-download" placement="top">
              <div :class="curTool == 11 ? 'text-active' : 'text'" @click="setToolType(11)">
                <span class="icon"></span>
                <span>文本</span>
              </div>
              <el-dropdown-menu slot="dropdown" class="dropdown-content">
                <div class="i-style">
                  <div class="font-size">
                    <span
                      v-for="(item, index) in fontSize"
                      :key="index"
                      :class="[item.class, { 'current-size': index === curFont }]"
                      @click="onSetTextSize(index, item.fontSize)"
                    >
                      {{ item.value }}
                    </span>
                  </div>
                </div>
                <div class="i-style">
                  <span
                    v-for="(i, index) in 9"
                    :key="index"
                    :class="['color-block', { 'current-color': index === curFontColor }]"
                    @click="onSetTextColor(index)"
                  ></span>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="graph-wrap">
            <el-dropdown trigger="click" class="i-download" placement="top">
              <div :class="graphTool ? 'graph-active' : 'graph'">
                <span class="icon"></span>
                <span>图形</span>
              </div>
              <el-dropdown-menu slot="dropdown" class="dropdown-content">
                <div class="i-hollow">
                  <span
                    :class="curTool == 6 ? 'rectangle-active' : 'rectangle'"
                    @click="setToolType(6)"
                  ></span>
                  <!--                  <span
                    :class="curTool == 13 ? 'square-active' : 'square'"
                    @click="setToolType(13)"
                  ></span>
                  <span
                    :class="curTool == 15 ? 'circle-active' : 'circle'"
                    @click="setToolType(15)"
                  ></span> -->
                  <span
                    :class="curTool == 5 ? 'oval-active' : 'oval'"
                    @click="setToolType(5)"
                  ></span>
                </div>
                <div class="i-solid">
                  <span
                    :class="curTool == 8 ? 'rectangle-active' : 'rectangle'"
                    @click="setToolType(8)"
                  ></span>
                  <!--                  <span
                    :class="curTool == 14 ? 'square-active' : 'square'"
                    @click="setToolType(14)"
                  ></span>
                  <span
                    :class="curTool == 16 ? 'circle-active' : 'circle'"
                    @click="setToolType(16)"
                  ></span> -->
                  <span
                    :class="curTool == 7 ? 'oval-active' : 'oval'"
                    @click="setToolType(7)"
                  ></span>
                </div>
                <div class="i-style">
                  <el-slider class="slider" @change="onSetThin" v-model="penThick"></el-slider>
                </div>
                <div class="i-style">
                  <span
                    v-for="(i, index) in 9"
                    :key="index"
                    :class="['color-block', { 'current-color': index === curColor }]"
                    @click="onSetBrushColor(index)"
                  ></span>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div :class="curTool == 2 ? 'eraser-active' : 'eraser'" @click="setToolType(2)">
            <span class="icon"></span>
            <span>橡皮擦</span>
          </div>
        </div>
        <div class="line"></div>
        <div class="operation-btn" v-show="boardEnable">
          <div class="revoke" @click="onRevoke">
            <span class="icon"></span>
            <span>撤销</span>
          </div>
          <div class="clear" @click="teduBoard.clear()">
            <span class="icon"></span>
            <span>清除</span>
          </div>
        </div>
        <div class="course-btn" v-show="role == 0">
          <span v-if="!isStartClass" @click="clickCourseBtn(1)">开始上课</span>
          <span v-else @click="clickCourseBtn(0)">立即下课</span>
        </div>
        <el-dialog
          title="课堂答疑"
          width="30%"
          :visible.sync="showQuestion"
          class="dialog-container"
          append-to-body
          destroy-on-close
        >
          <div class="container-wrap">
            <!-- 学生视角 -->
            <div class="question-wrap" v-show="role == 1">
              <div class="c-title">点击 + 上传图片</div>
              <div class="upload-wrap">
                <el-upload
                  :action="action"
                  :headers="headers"
                  list-type="picture-card"
                  :on-preview="handlePictureCardPreview"
                  :on-remove="handleRemove"
                  :on-success="successUpload"
                >
                  <i class="el-icon-plus"></i>
                </el-upload>
                <el-dialog :visible.sync="ImgdialogVisible" append-to-body top="1vh">
                  <img width="100%" :src="questionImageUrl" alt="" />
                </el-dialog>
              </div>
              <div class="wrap-row">
                <el-button plain class="cancleBtn">删除</el-button>
                <el-button class="subBtn" type="primary" round @click="sendQuestion">
                  确定
                </el-button>
              </div>
            </div>
            <!-- 老师视角 -->
            <div class="container-wrap" v-show="role == 0">
              <div class="tab-bar">
                <div class="tab-list">
                  <span
                    :class="questionTypeIndex === index ? 't-current' : 't-item'"
                    v-for="(item, index) in questionTypeList"
                    :key="index"
                    @click="changeQuestionType(index)"
                  >
                    {{ item }}
                  </span>
                </div>
              </div>
              <div class="img-wrap" v-show="questionTypeIndex == 0">
                <div class="imgList">
                  <div
                    @click="onChooseQues(index, item)"
                    :class="index == curImg ? 'img-item-active' : 'img-item'"
                    v-for="(item, index) in stuQuesImgList"
                    :key="index"
                  >
                    <img class="questionImg" :src="item.pazzleUrl" />
                    <span class="i-tag">{{ item.studentNickName }}</span>
                  </div>
                </div>
              </div>
              <div class="img-wrap" v-show="questionTypeIndex == 1">
                <div class="imgList">
                  <div
                    @click="onChooseQues(index, item)"
                    :class="index == curImg ? 'img-item-active' : 'img-item'"
                    v-for="(item, index) in stuQuesImgListF"
                    :key="index"
                  >
                    <img class="questionImg" :src="item.pazzleUrl" />
                    <span class="i-tag">{{ item.studentNickName }}</span>
                  </div>
                </div>
              </div>
              <div class="wrap-row">
                <el-button plain class="cancleBtn">删除</el-button>
                <el-button class="subBtn" type="primary" round @click="checkQuestion">
                  查看
                </el-button>
              </div>
            </div>
          </div>
        </el-dialog>
        <!-- 答题器遮盖层 -->
        <el-dialog
          title="答题器"
          width="30%"
          :visible.sync="showResponder"
          class="dialog-container"
          append-to-body
        >
          <!-- 答题器 -->
          <div class="container-wrap" v-if="responderType == 0">
            <div class="tab-bar">
              <div class="tab-list">
                <span
                  :class="quesTypeIndex === index ? 't-current' : 't-item'"
                  v-for="(item, index) in quesTypeList"
                  :key="index"
                  @click="changeQuesType(index)"
                >
                  {{ item }}
                </span>
              </div>
            </div>
            <div class="content" v-show="quesTypeIndex == 0">
              <div class="title">点击字母设置正确答案，支持多选</div>
              <div class="options">
                <span
                  :class="{ 'o-choose': item.isChoose == 1 }"
                  v-for="(item, index) in optionList"
                  :key="index"
                  @click="chooseOption(item)"
                >
                  {{ item.value }}
                </span>
                <span class="add" v-show="role == 0 && optionList.length < 26" @click="addOption">
                  +
                </span>
                <span
                  class="minus"
                  v-show="role == 0 && optionList.length > 2"
                  @click="minusOption"
                >
                  -
                </span>
              </div>
            </div>
            <div class="content" v-show="quesTypeIndex == 1">
              <div class="title">点击对错，设置正确答案</div>
              <div class="options">
                <span
                  :class="curJuage == 1 ? 'right-active' : 'right'"
                  @click="onChooseJuage(1)"
                ></span>
                <span
                  :class="curJuage == 0 ? 'wrong-active' : 'wrong'"
                  @click="onChooseJuage(0)"
                ></span>
              </div>
            </div>
            <div class="wrap-row">
              <!-- 老师答题按钮 -->
              <el-button
                v-if="role == 0"
                class="subBtn"
                type="primary"
                round
                @click="teacherStartAnswer"
              >
                开始答题
              </el-button>
              <!-- 学生答题按钮 -->
              <el-button
                v-if="role == 1"
                :class="stuDisableSubmit ? 'cancleBtn' : 'subBtn'"
                type="primary"
                round
                @click="studentSendAnswer"
              >
                提交答案
              </el-button>
            </div>
          </div>
          <!-- 答题详情 -->
          <div class="container-wrap" v-if="responderType == 1">
            <div class="tab-bar">
              <div class="tab-list">
                <span
                  :class="responderIndex === index ? 't-current' : 't-item'"
                  v-for="(item, index) in responderList"
                  :key="index"
                  @click="changeResponder(index)"
                >
                  {{ item }}
                </span>
              </div>
            </div>
            <div class="content" v-show="responderIndex == 0">
              <div class="c-title">
                答题时间:
                <span class="blue-text">{{ countTime }}</span>
              </div>
              <div class="c-option">
                <div class="c-title">
                  正确答案：
                  <span>{{ questionAnswer }}</span>
                  <span></span>
                  <!--    <span class="blue-text">
                    <span v-for="(item, index) in rightOption" :key="index">{{ item.value }}</span>
                  </span> -->
                </div>
                <div class="c-chatrs"></div>
              </div>
              <div class="c-detail">
                <div class="c-title">
                  已答题人数：
                  <span class="blue-text">{{ this.answerCount }}</span>
                  /
                  <span class="gray-text">{{ this.totalCount }}</span>
                </div>
                <div class="c-title">
                  正确率：
                  <span class="blue-text">{{ this.accuracy }}</span>
                </div>
              </div>
            </div>
            <div class="content" v-show="responderIndex == 1">
              <div class="c-title">
                答题时间:
                <span class="blue-text">{{ countTime }}</span>
              </div>
              <div class="c-list">
                <div class="c-item" v-for="(item, index) in studentAnswersList" :key="index">
                  <span>{{ item.studentNickName }}</span>
                  <span>{{ formatSeconds(item.answerDuration) }}</span>
                  <span>{{ item.answerFlag == 'y' ? '回答正确' : '回答错误' }}</span>
                  <span v-if="questionType == 3">{{ item.studentAnswer == 1 ? '对' : '错' }}</span>
                  <span v-else>{{ item.studentAnswer }}</span>
                </div>
              </div>
            </div>
            <div class="wrap-row">
              <el-button class="subBtn" type="primary" round @click="stopAnswerQues">
                结束答题
              </el-button>
            </div>
          </div>
        </el-dialog>
        <!-- 课件管理遮罩层 -->
        <el-dialog
          title="课件管理"
          width="45%"
          :visible.sync="showCourseware"
          class="dialog-container"
          append-to-body
        >
          <div class="container-wrap courseware-wrap">
            <!--          <div class="c-header">
              <div class="c-title">上传课件</div>
              <div class="c-btn">
                    <el-upload
                  ref="fileUpload"
                  class="c-uploader"
                  :action="action"
                  :headers="headers"
                  :multiple="false"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :before-upload="beforeUpload"
                  :on-success="uploadSuccess"
                  :on-exceed="handleExceed"
                  :show-file-list="false"
                >
                </el-upload>
                <input
                  id="file_input"
                  type="file"
                  @change="uploadFile"
                  accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.openxmlformats-officedocument.presentationml.presentation,image/*, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.ms-powerpoint, application/vnd.ms-excel"
                />
                <div class="c-upload" @click="uploadFile">
                  <div class="icon-upload"></div>
                  上传课件
                </div>
                <div class="c-upload" @click="onShowUpload">
                <div class="icon-upload"></div>
                上传课件
              </div>
              </div>
            </div> -->
            <el-table :data="courseWareList" style="width: 100%" stripe>
              <el-table-column prop="name" label="课件名称" width="220"></el-table-column>
              <el-table-column prop="fileType" label="格式" width="90"></el-table-column>
              <el-table-column label="状态" width="90">
                <template slot-scope="scope">
                  <p v-if="scope.row.status == '0'">转码中</p>
                  <p v-if="scope.row.status == '1'">正常</p>
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="上传时间" width="120"></el-table-column>
              <el-table-column label="操作" width="100">
                <template slot-scope="scope">
                  <el-button
                    size="mini"
                    type="primary"
                    @click="addCourseWare(scope.$index, scope.row)"
                  >
                    添加
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-dialog>
        <!-- 聊天遮罩层 -->
        <el-dialog
          title="聊天"
          width="30%"
          :visible.sync="showChat"
          class="dialog-container"
          append-to-body
        >
          <div class="chat-room">
            <div class="content" id="msg_box">
              <div class="c-wrap" v-for="(msg, index) in msgs" :key="index">
                <!-- JSON.parse -->
                <div v-if="msg.type == '1'" class="c-other">
                  <div class="name">{{ msg.nickName }}</div>
                  <div class="c-content">
                    <div
                      class="avatar"
                      :style="{ backgroundImage: 'url(' + msg.avatar + ')' }"
                    ></div>
                    <div class="text">{{ msg.content }}</div>
                  </div>
                </div>
                <div v-if="msg.type == '2'" class="c-me">
                  <div class="name">{{ msg.nickName }}</div>
                  <div class="c-content">
                    <div class="text">{{ msg.content }}</div>
                    <div
                      class="avatar"
                      :style="{ backgroundImage: 'url(' + msg.avatar + ')' }"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="reply">
              <div class="r-header">
                <span class="r-title">互动聊天</span>
                <div>
                  <span class="r-text">全员禁言</span>
                  <el-switch
                    v-model="banned"
                    active-color="#13ce66"
                    inactive-color="#E9E9EA"
                    @change="onChangeChatState"
                  ></el-switch>
                </div>
              </div>
              <el-input
                class="textareaInput"
                type="textarea"
                placeholder="请输入内容"
                v-model="imMsg.common.data"
                @keyup.enter.native="sendCustomGroupMsg"
              ></el-input>
              <div class="r-wrap">
                <el-button class="r-btn" @click="sendCustomGroupMsg">发送</el-button>
              </div>
            </div>
          </div>
        </el-dialog>
      </div>
    </div>
    <div class="video-box">
      <!-- v-show="cameraState !== 0" -->
      <div
        :class="[
          teacher.speaking ? 'isSpeaking' : '',
          teacher.fullScreen ? 'teacher_full' : 'teacher'
        ]"
      >
        <div
          v-if="teacher.action == 0"
          class="avatar"
          :style="{ backgroundImage: 'url(' + teacher.avatar + ')' }"
        ></div>
        <div class="info">
          <div class="left">
            <div class="icon"></div>
            <div class="name">{{ teacher.nickName }}</div>
          </div>
          <div class="right" v-if="role == 0">
            <div :class="cameraState ? 'camera' : 'camera-close'"></div>
            <div :class="micState ? 'microphone' : 'microphone-close'"></div>
            <div
              :class="teacher.fullScreen ? 'full-screen' : 'mini-screen'"
              @click="toggleScreen(teacher.userCode)"
            ></div>
          </div>
        </div>
        <div
          v-show="teacher.action == 1"
          id="video_teachers"
          :class="teacher.fullScreen ? 'video_teachers_full' : 'video_teachers'"
        ></div>
      </div>
      <!--      <div class="v-wrap">
        <span class="t-title">学生列表</span>
        <div class="btns">
          <span class="phone"></span>
          <span class="list"></span>
          <span class="toggle"></span>
        </div>
      </div> -->
      <!--      <div class="tab">
        <span :class="curTab == 1 ? 't-current' : 't-item'" @click="onChangeTab(1)">
          上课中({{ studentInRoom.length }})
        </span>
        <span :class="curTab == 2 ? 't-current' : 't-item'" @click="onChangeTab(2)">
          未上课({{ studentList.length }})
        </span>
      </div> -->
      <div class="list-wrap">
        <div class="list-title">
          <span>上课中（{{ studentInRoom.length }}) / 未上课（{{ studentList.length }})</span>
        </div>
        <div class="student-list">
          <div
            :class="[
              item.action == 1 ? 's-item-active' : 's-item-in',
              item.fullScreen ? 's-item-active-full' : '',
              item.speaking ? 'isSpeaking' : ''
            ]"
            v-for="(item, index) in studentInRoom"
            :key="index"
          >
            <!-- v-if="item.action == 0" -->
            <div class="avatar" :style="{ backgroundImage: 'url(' + item.avatar + ')' }"></div>
            <div class="info">
              <div class="left">
                <!--    <div v-show="item.action == 1" class="icon-mic"></div>
                <div v-show="item.action == 1" class="icon-video"></div> -->
                <div
                  v-show="role == 0"
                  :class="item.draw ? 'icon-pen-active' : 'icon-pen'"
                  @click="toggleDraw(item)"
                ></div>
                <div class="name">{{ item.nickName }}</div>
              </div>
              <div class="right" v-if="role == 0">
                <div v-if="item.action == 0" class="phone" @click="onStage(item)"></div>
                <div v-if="item.action == 1" class="phone-close" @click="downStage(item)"></div>
                <div
                  :class="item.fullScreen ? 'full-screen' : 'mini-screen'"
                  @click="toggleScreen(item.userCode)"
                ></div>
                <div class="leave" @click="leaveRoom(item)"></div>
              </div>
            </div>
            <div v-if="item.action == 1" id="video_student"></div>
          </div>
        </div>
        <div class="student-list">
          <div class="s-item-out" v-for="(item, index) in studentList" :key="index">
            <div class="avatar" :style="{ backgroundImage: 'url(' + item.avatar + ')' }"></div>
            <div class="info">
              <div class="name">{{ item.nickName }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    lessonId: {
      type: String,
      required: true
    },
    lessonStatus: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      // 是否有新消息
      hasNewMsg: false,
      time: {},
      // 是否有学生提问
      isStuAskQues: false,
      // 问题类型 1单选 2多选 3判断
      questionType: '',
      // 总人数
      totalCount: '',
      // 答题人数
      answerCount: '',
      // 问题答案
      questionAnswer: '',
      // 学生回答列表
      stuAnsStatList: {},
      // 学生回答状态
      stuAnsStat: {},
      // 课程详情
      lessonDetail: {},
      // 已解决的学生问题
      stuQuesImgListF: [],
      // 当前问题对象
      curQuestion: {},
      // 当前问题答疑图片index
      curImg: -1,
      user: {
        username: 'kh'
      },
      // 学生提交的照片
      stuQuesImgList: [],
      // 课程是否开始
      isStartClass: false,
      // 上台人数 默认老师在台
      onStageNum: 1,
      // 上传文件列表
      fileList: [],
      // 问题答疑类型
      questionTypeList: ['未答题', '已答题'],
      // 问题答疑类型index
      questionTypeIndex: 0,
      // 禁止学生提交答案
      stuDisableSubmit: false,
      // 回答正确人数
      rightStu: 0,
      // 正确率
      accuracy: '',
      classTimes: {},
      //定义时，分，秒，毫秒并初始化为0
      classHour: 0,
      classMinute: 0,
      classSecond: 0,
      classTime: '00:00:00',
      //定义时，分，秒，毫秒并初始化为0
      minute: 0,
      second: 0,
      countTime: '00:00',
      // 学生提交答案列表
      studentAnswersList: [],
      // 问答详情
      responderList: ['答题统计', '答题明细'],
      // 问答详情index
      responderIndex: 0,
      // 答题器类型  0:答题器  1:答题统计
      responderType: 0,
      // 选项列表
      rightOption: [],
      // 当前判断题index
      curJuage: -1,
      // 答疑图片上传遮罩
      ImgdialogVisible: false,
      // 图片url
      questionImageUrl: '',
      // 是否展示答疑遮罩
      showQuestion: false,
      // 当前直播操作按钮index
      curLive: -10,
      // 当前缩略图index
      curThumb: 0,
      // 展开缩略图
      openThumb: false,
      // 禁言开关
      banned: false,
      // 聊天回复框内容
      replyContent: '',
      // 是否显示聊天窗口
      showChat: false,
      // 开启摄像头权限
      cameraEnable: true,
      // 开启麦克权限
      micEnable: true,
      // 画板权限
      boardEnable: true,
      // 全部学生列表
      allStudentList: [],
      // action: 0 下台  1上台  draw：0不能绘画  1可以绘画 0
      studentList: [],
      teacher: {},
      // 在线学生列表
      studentInRoom: [],
      // 白板实例
      teduBoard: {},
      // tic实例
      tic: {},
      // 是否显示答题遮罩
      showResponder: false,
      // 是否显示课件
      showCourseware: false,
      quesTypeIndex: 0,
      // 问题类型
      quesTypeList: ['选择题', '判断题'],
      optionList: [
        { value: 'A', isChoose: 0 },
        { value: 'B', isChoose: 0 },
        { value: 'C', isChoose: 0 },
        { value: 'D', isChoose: 0 }
      ],
      // 所有选项
      allOptionList: [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z'
      ],
      curOptionIndex: 3,
      penThick: 60,
      colorBar: [
        '#ff0000',
        '#000000',
        '#949699',
        '#FF6A0C',
        '#F7B502',
        '#02BF50',
        '#43D7B5',
        '#0069F2',
        '#6336FF'
      ],
      curColor: 0,
      curTool: 0,
      curPen: 0,
      graphTool: false,
      curTab: 1,
      role: '',
      action: 'http://api.100niu.com/live/upload/file',
      headers: {
        Authorization: 'Bearer ' + this.$.data.get('Token')
      },
      stateConfig: {
        '0': '转码中',
        '1': '正常'
      },
      courseWareList: [],
      fontSize: [
        { value: '小', class: 'small', fontSize: 12 },
        { value: '中', class: 'mid', fontSize: 15 },
        { value: '大', class: 'big', fontSize: 18 },
        { value: '特', class: 'max', fontSize: 21 }
      ],
      // 摄像头状态
      cameraState: 0,
      // 麦克状态
      micState: 0,
      curFont: 0,
      curFontColor: 0,
      boardWidth: 522,
      sdkAppId: this.$.data.get('sdkAppId'),
      userId: this.$.data.get('userCode'),
      userSig: this.$.data.get('userSig'),
      //房间信息
      roomInfo: '',
      roomID: '',
      //音视频及设备
      enableCamera: true,
      enableMic: true,
      cameraIndex: 0,
      micIndex: 0,
      devices: {
        camera: [],
        mic: []
      },
      //board(涂鸦)
      drawEnable: false, //是否可以涂鸦
      synDrawEnable: true, //是否将你画的涂鸦同步给其他人
      toolType: 1,
      brushThin: 100,
      backgroundImage: '背景图',
      backgroundImageH5: '背景图H5',
      backgroundColor: '#ff0000',
      globalBackgroundColor: '#ff0000',
      brushColor: '#ff0000',
      textColor: '#ff0000',
      textStyle: '#ff0000',
      textFamily: 'sans-serif,serif,monospace',
      textSize: 320,
      scaleSize: 100,
      fitMode: 1,
      ration: '16:9',
      canRedo: 0,
      canUndo: 0,

      //board(白板操作)
      boardData: {
        currentBoardId: null, //当前白板ID
        boardIdlist: [], //白板ID列表
        current: 0, //当前白板index
        total: 0 //总页数
      },

      //board(文件操作)
      currentFileId: null, // 当前文件Id
      fileInfoList: [], // 所有文件信息
      thumbUrls: [], // 缩略图

      //消息
      msgs: [],
      imMsg: {
        common: {},
        custom: {}
      },

      //当前用户状态
      STATUS_UNINIT: 0,
      STATUS_UNLOGIN: 1,
      STATUS_LOGINED: 2,
      STATUS_INCLASS: 3,
      status: 0,

      isPushing: 0, // 是否正在推流
      isPushCamera: 0, // 是否推摄像头流
      remoteVideos: {},
      imReady: false,
      handsUpList: []
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getClassInfo();
  },
  watch: {},
  methods: {
    // 初始化
    getClassInfo() {
      this.getLessonInfo();
      this.getTeacher();
      this.getOnlineStudents();
      this.classTimeStart();
      this.getQuestionList();
    },
    // 获取老师列表
    getTeacher() {
      this.get(`/live/lvb/member/teacher/${this.lessonId}`, {}, e => {
        this.teacher = e.data;
        this.teacher.action = 0;
        this.teacher.fullScreen = false;
        // 鉴别用户身份 创建或者进入课堂
        this.IdentifyUser();
        console.log(this.teacher, 'this.teacher------------');
      });
    },
    // 获取所有学生列表
    getAllStudentList() {
      this.get(`/live/lvb/member/students/${this.lessonId}`, {}, e => {
        if (e.code == 200) {
          this.allStudentList = JSON.parse(JSON.stringify(e.data));
          this.allStudentList.forEach(item => {
            item.action = 0;
            item.draw = 0;
            item.fullScreen = false;
            item.speaking = false;
          });
          this.studentList = e.data;
          this.studentList.forEach(item => {
            item.action = 0;
            item.draw = 0;
            item.fullScreen = false;
            item.speaking = false;
          });
          // 在全部学生列表中取出在线学生列表得到  未在线学生列表
          for (let i = 0; i < this.studentInRoom.length; i++) {
            for (let j = 0; i < this.studentList.length; j++) {
              if (this.studentInRoom[i].userCode == this.studentList[j].userCode) {
                this.studentList.splice(j, 1);
                break;
              }
            }
          }
          console.log(this.studentList, '--------------studentList------------');
          console.log(this.studentInRoom, '--------------this.studentInRoom------------');
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    // 取得在线学生列表
    getOnlineStudents() {
      this.get(`/live/lvb/member/onlineStudents/${this.lessonId}`, {}, e => {
        if (e.code == 200) {
          this.studentInRoom = e.data;
          this.studentInRoom.forEach(item => {
            item.action = 0;
            item.draw = 0;
            item.speaking = false;
          });
          this.getAllStudentList();
          console.log(this.studentInRoom, 'studentInRoom----------------');
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    // 鉴别用户身份
    IdentifyUser() {
      // 判断当前用户 role:0 老师; 1学生
      this.role = 0;
      // this.studentInRoom.push(this.teacher);
      // 开启摄像头权限
      this.cameraEnable = true;
      // 开启麦克权限
      this.micEnable = true;
      // 画板权限
      this.boardEnable = true;
      //可以涂鸦
      this.drawEnable = true;
      // 创建或者进入课堂
      this.init();
      // TODO 临时添加创建教室代码
      // setTimeout(() => {
      //   this.createClassroom();
      // }, 500);
      // setTimeout(() => {
      //   this.joinClassroom();
      // }, 1000);
    },
    getLessonInfo() {
      this.get('/live/lvb/lesson/getLesson', { lessonId: this.lessonId }, e => {
        if (e.code == 200) {
          this.lessonDetail = e.data;
          this.roomID = e.data.id;
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    classTimeStart() {
      this.classTimes = setInterval(this.classTimer, 1000);
    },
    classTimer() {
      //定义计时函数
      this.classSecond = this.classSecond + 1;
      if (this.classSecond >= 60) {
        this.classSecond = 0;
        this.classMinute = this.classMinute + 1; //分钟
      }
      if (this.classMinute >= 60) {
        this.classMinute = 0;
        this.classHour = this.classHour + 1; //小时
      }
      this.classTime =
        this.toDub(this.classHour) +
        ':' +
        this.toDub(this.classMinute) +
        ':' +
        this.toDub(this.classSecond);
    },
    timeStart() {
      this.time = setInterval(this.timer, 1000);
    },
    reset() {
      //重置
      clearInterval(this.time);
      this.minute = 0;
      this.second = 0;
      this.countTime = '00:00';
    },
    timer() {
      //定义计时函数
      this.second = this.second + 1;
      if (this.second >= 60) {
        this.second = 0;
        this.minute = this.minute + 1; //分钟
      }
      this.countTime = this.toDub(this.minute) + ':' + this.toDub(this.second);
    },
    toDub(n) {
      //补0操作
      if (n < 10) {
        return '0' + n;
      } else {
        return '' + n;
      }
    },
    // 折叠缩略图
    toggleThumb() {
      this.openThumb = !this.openThumb;
      setTimeout(() => {
        this.teduBoard.resize();
      }, 200);
    },
    onChangeTab(index) {
      this.curTab = index;
    },
    setEraserCursor() {
      // 白板工具设置为橡皮擦
      // this.teduBoard.setToolType(TEduBoard.TEduBoardToolType.TEDU_BOARD_TOOL_TYPE_ERASER);
      // 自定义橡皮擦图标
      this.teduBoard.setCursorIcon(TEduBoard.TEduBoardToolType.TEDU_BOARD_TOOL_TYPE_ERASER, {
        cursor: 'url',
        url:
          'https://bainiu-1305813075.cos.ap-nanjing.myqcloud.com/bainiu/2f168673b22344f19647473119e210f4.png',
        offsetX: 0,
        offsetY: 0
      });
    },

    // setPenCursor() {
    //   // 白板工具设置为画笔工具
    //  this.teduBoard.setToolType(TEduBoard.TEduBoardToolType.TEDU_BOARD_TOOL_TYPE_PEN);
    //   // 自定义画笔工具图标
    //   this.teduBoard.setCursorIcon(TEduBoard.TEduBoardToolType.TEDU_BOARD_TOOL_TYPE_PEN, {
    //     cursor: 'pointer'
    //   });
    // },
    // 点击课程按钮
    clickCourseBtn(type) {
      if (type == 0) {
        this.$confirm('您确定要立即下课吗？', '下课提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
          .then(() => {
            this.$message({
              type: 'success',
              message: '下课成功'
            });
            this.post(`/live/lvb/room/classOver/${this.lessonId}`, {}, e => {
              if (e.code == 200) {
                this.isStartClass = false;
                // 下课停止推流
                this.stopPush(0);
                this.$emit('finishClass', true);
              } else {
                this.$.ui.toast(e.msg);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            });
          });
      } else if (type == 1) {
        this.post(`/live/lvb/room/startClass/${this.lessonId}`, {}, e => {
          if (e.code == 200) {
            this.isStartClass = true;
            // 上课推流
            this.startRTC();
          } else {
            this.$.ui.toast(e.msg);
          }
        });
      }
    },
    // 点击聊天
    onShowChat(index) {
      this.curLive = index;
      this.showChat = !this.showChat;
      this.hasNewMsg = false;
    },
    // 点击课件
    onShowWare(index) {
      this.curLive = index;
      this.showCourseware = !this.showCourseware;
      this.getCourseWares();
    },
    // 更改问题类型
    changeQuesType(index) {
      this.quesTypeIndex = index;
    },
    // 添加选项
    addOption() {
      this.curOptionIndex++;
      if (this.curOptionIndex >= 26) {
        this.$.ui.toast('选项个数到达上限');
      } else {
        let obj = { value: this.allOptionList[this.curOptionIndex], isChoose: 0 };
        this.optionList.push(obj);
      }
    },
    minusOption() {
      if (this.optionList.length > 2) {
        this.curOptionIndex--;
        this.optionList.pop();
      }
    },
    chooseOption(item) {
      if (item.isChoose == 1) {
        item.isChoose = 0;
        let index = this.rightOption.indexOf(item);
        this.rightOption.splice(index, 1);
      } else {
        item.isChoose = 1;
        this.rightOption.push(item);
      }
    },
    // 工具类型切换
    setToolType(toolType) {
      this.graphTool = false;
      this.curTool = toolType
      this.curPen = toolType
      if (
        toolType == 6 ||
        toolType == 8 ||
        toolType == 13 ||
        toolType == 14 ||
        toolType == 15 ||
        toolType == 16 ||
        toolType == 5 ||
        toolType == 7
      ) {
        this.graphTool = true;
      }
      this.teduBoard.setToolType(toolType);
    },
    // 设置字体大小
    onSetTextSize(index, fontSize) {
      this.curFont = index;
      let fontsize = (fontSize * 10000) / this.boardWidth;
      this.teduBoard.setTextSize(fontsize);
    },
    onRevoke() {
      this.teduBoard.undo();
    },
    // 上传成功
    uploadSuccess(response) {
      // 上传课件后，更新课件
      console.log(response);
      var file = response.data;
      if (/\.(bmp|jpg|jpeg|png|gif|webp|svg|psd|ai)/i.test(file)) {
        // this.teduBoard.setBackgroundImage({
        //   data: file,
        //   userData: 'image'
        // });
        this.teduBoard.addImageElement({
          data: file,
          userData: 'image'
        });
      } else {
        this.teduBoard.applyFileTranscode(
          {
            data: file,
            userData: 'hello'
          },
          {
            minResolution: '960x540',
            isStaticPPT: false,
            thumbnailResolution: '200x200'
          }
        );
      }
    },
    beforeUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1);
      const file1 = testmsg === 'ppt';
      const file2 = testmsg === 'pptx';
      const file3 = testmsg === 'doc';
      const file4 = testmsg === 'docx';
      const file5 = testmsg === 'pdf';
      const isLt50M = file.size / 1024 / 1024 < 50;
      if (!file1 && !file2 && !file3 && !file4 && !file5) {
        this.$message.error('上传文件格式支持 ppt/pptx 、doc/docx、pdf ！');
      }
      if (!isLt50M) {
        this.$message.error('上传文件大小不能超过 50MB!');
      }
      let isFile = file1 || file2 || file3 || file4 || file5;
      return isFile && isLt50M;
    },
    handleEdit(index, row) {
      console.log(index, row);
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handlePictureCardPreview(file) {
      this.questionImageUrl = file.url;
      this.ImgdialogVisible = true;
      console.log(FileList, '------------================');
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length +
          fileList.length} 个文件`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    // 初始化业务数据
    initData() {
      this.msgs = [];

      this.devices = {
        camera: [],
        mic: []
      };

      this.cameraIndex = 0;
      this.micIndex = 0;

      this.imMsg = {
        common: {
          data: '',
          toUser: ''
        },
        custom: {
          data: '',
          toUser: ''
        }
      };
      this.drawEnable = false;
      this.synDrawEnable = true; //是否将你画的涂鸦同步给其他人
      this.toolType = 1;
      this.brushThin = 100;
      this.backgroundImage = '背景图';
      this.backgroundImageH5 = '背景图H5';
      this.backgroundColor = '#ff0000';
      this.globalBackgroundColor = '#ff0000';
      this.brushColor = '#ff0000';
      this.textColor = '#ff0000';
      this.textStyle = '#ff0000';
      this.textFamily = 'sans-serif,serif,monospace';
      this.textSize = 320;
      this.scaleSize = 100;
      this.fitMode = 1;
      this.ration = '16:9';
      this.canRedo = 0;
      this.canUndo = 0;
    },

    clearClassInfo() {
      //设备信息
      this.remoteVideos = {};
      this.enableCamera = true;
      this.enableMic = true;
      this.isPushing = 0;
      this.isPushCamera = 0;

      //白板信息
      this.boardData.currentBoardId = null;
      this.boardData.boardIdlist = [];
      this.boardData.current = 0;
      this.boardData.total = 0;

      document.getElementById('paint_box').innerHTML = '';

      var divvideo = document.querySelector('#video_wrap');
      while (divvideo.hasChildNodes()) divvideo.removeChild(divvideo.firstChild);

      this.fileInfoList = [];
      this.currentFileId = null;
    },

    // 初始化SDK
    init() {
      if (this.status == this.STATUS_UNINIT) {
        this.initData();
        this.tic = null;
        this.tic = new TIC({});
        this.tic.init(this.sdkAppId, res => {
          if (res.code) {
            this.showErrorTip('初始化失败，code:' + res.code + ' msg:' + res.desc);
            // this.showMessageInBox('TIC', 'init failed. code=' + res.code);
          } else {
            // this.showMessageInBox('TIC', 'init Succ.');
            this.status = this.STATUS_UNLOGIN;

            this.tim = this.tic.getImInstance(); //获取im实例
            // 监听im的状态，如果不是ready状态，创建群，加群，发消息等操作都会失败
            // 监听的im的事件，一定要调用off注销，否则会出现重复监听的问题
            this.tim.on(window.TIM.EVENT.SDK_READY, this.imReadyHandler);
            this.login();
          }
        });
      } else {
        this.login();
      }
    },

    imReadyHandler() {
      // im 已经准备好了
      this.imReady = true;
    },

    // 登录
    login() {
      this.tic.login(
        {
          userId: this.userId,
          userSig: this.userSig
        },
        res => {
          if (res.code) {
            this.showErrorTip('登录失败');
            // this.showMessageInBox('TIC', 'login Failed, code=' + res.code);
            console.error(res.code);
          } else {
            this.status = this.STATUS_LOGINED;

            this.showTip('登录成功');
            // this.showMessageInBox('TIC', 'login Succ,userid=' + this.userId);
            // 增加事件监听
            this.addTICMessageListener();
            this.addTICEventListener();
            this.addTICStatusListener();
            this.joinClassroom();
          }
        }
      );
    },

    // 登出
    logout() {
      if (this.status == this.STATUS_INCLASS) {
        this.quitClassroom(res => {
          this.logout_internal();
        });

        return;
      }
      this.logout_internal();
    },

    logout_internal() {
      this.tic.logout(res => {
        if (res.code) {
          this.showErrorTip('登出失败');
          // this.showMessageInBox('TIC', 'logout Failed, code=' + res.code);
          console.error(res);
        } else {
          this.initData();
          this.status = this.STATUS_UNLOGIN;
          this.imReady = false; // im状态ready设置为false
          // 注销im事件
          this.tim.off(window.TIM.EVENT.SDK_READY, this.imReadyHandler);

          this.showTip('登出成功');
          // this.showMessageInBox('TIC', 'logout Succ');
          // 删除事件监听
          this.tic.removeTICMessageListener();
          this.tic.removeTICEventListener();
          this.tic.removeTICStatusListener();
        }
      });
    },

    // 创建房间
    // 需要监听im的状态，如果不是ready状态，创建群，加群，发消息等操作都会失败
    createClassroom() {
      if (!this.roomID) {
        this.showErrorTip('创建房间,房间号不能为空');
        return;
      }

      this.tic.createClassroom(
        {
          classId: this.roomID,
          classScene: TIC.CONSTANT.TICClassScene.TIC_CLASS_SCENE_VIDEO_CALL // 1：直播模式 0: 实时模式
        },
        res => {
          if (res.code) {
            if (res.code == 10021) {
              this.showTip('该课堂已被他人创建，请直接加入');
            } else if (res.code == 10025) {
              this.showTip('您已经创建过这个课堂，请直接加入');
            } else {
              this.showErrorTip('创建课堂失败');
              console.error(res);
              // this.showMessageInBox('TIC', 'createClassroom error:' + res.code);
            }
          } else {
            this.showTip('创建课堂成功');
            // this.showMessageInBox('TIC', 'createClassroom Succ:');
          }
        }
      );
    },

    // 销毁课堂
    destroyClassroom() {
      if (!this.roomID) {
        this.showErrorTip('销毁课堂,房间号不能为空');
        return;
      }

      this.tic.destroyClassroom(this.roomID, res => {
        if (res.code) {
          this.showErrorTip('销毁课堂失败');
          // this.showMessageInBox('TIC', 'destroyClassroom Failed:' + res.code);
          console.error(res);
        } else {
          this.initData();

          this.status = this.STATUS_LOGINED;
          this.clearClassInfo();

          this.showTip('销毁课堂成功');
          // this.showMessageInBox('TIC', 'destroyClassroom Succ:');
        }
      });
    },

    // 进入房间
    joinClassroom() {
      if (!this.roomID) {
        this.showErrorTip('进入房间,房间号不能为空');
        return;
      }

      if (this.status == this.STATUS_INCLASS) {
        // this.showMessageInBox('TIC', 'this user is in classs, roomID=' + this.roomID);
        return;
      }

      // 需要监听im的状态，如果不是ready状态，创建群，加群，发消息等操作都会失败
      this.tic.joinClassroom(
        {
          // compatSaas: true,
          classId: this.roomID
        },
        {
          // mode: TIC.CONSTANT.TICClassScene.TIC_CLASS_SCENE_LIVE //直播模式，支持1000人以上场景
          mode: TIC.CONSTANT.TICClassScene.TIC_CLASS_SCENE_VIDEO_CALL // //实时通话模式，支持1000人以下场景，低延时
          // role: TIC.CONSTANT.TICRoleType.TIC_ROLE_TYPE_ANCHOR // 主播，只在TIC.CONSTANT.TICClassScene.TIC_CLASS_SCENE_LIVE模式下有效
          // role: TIC.CONSTANT.TICRoleType.TIC_ROLE_TYPE_AUDIENCE // 观众（观众角色没有发布本地流的权限，只有收看远端流的权限。如果观众想要连麦跟主播互动， 请先通过 switchRole() 切换角色到主播 anchor 后再发布本地流），只在TIC.CONSTANT.TICClassScene.TIC_CLASS_SCENE_LIVE模式下有效
        },
        {
          id: 'paint_box',
          ratio: '16:9',
          smoothLevel: 0,
          boardContentFitMode: 1,
          toolType: 1,
          progressEnable: true,
          progressBarUrl: 'https://resources-tiw.qcloudtrtc.com/board/icons/Gear-0.2s-48px.png',
          drawEnable: true
        },
        res => {
          if (res.code) {
            if (res.code == 10010) {
              this.showErrorTip('课堂已经被解散');
            } else {
              this.showErrorTip('加入课堂失败');
            }
            setTimeout(() => {
              this.$emit('finishClass', true);
            }, 3000);
            // this.showMessageInBox(
            //   'TIC',
            //   'joinClassroom Failed,room=' + this.roomID + 'code=' + res.code
            // );
            console.error(res);
          } else {
            this.status = this.STATUS_INCLASS;
            this.inRoom(this.userId);
            this.showTip('加入课堂成功');
            // this.showMessageInBox('TIC', 'joinClassroom Succ, room=' + this.roomID);
            window.teduBoard = this.teduBoard = this.tic.getBoardInstance();
            this.initBoardEvent();
          }
        }
      );
    },

    /**
     * 退出课堂
     */
    quitClassroom(callback) {
      if (!this.roomID) {
        // this.showErrorTip('退出课堂,房间号不能为空');
        return;
      }

      this.tic.quitClassroom(res => {
        if (res.code) {
          // this.showErrorTip('退出课堂失败');
          // this.showMessageInBox('TIC', 'quitClassroom Failed, code=' + res.code);
          console.log('quitClassroom error' + res.code);
        } else {
          this.initData();
          this.status = this.STATUS_LOGINED;
          this.showTip('退出课堂成功');
          this.logout();
          this.outRoom(this.userId);
          this.clearClassInfo();
          // this.showMessageInBox('TIC', 'quitClassroom Succ');
        }

        callback &&
          callback({
            code: 0
          });
      });
    },
    inRoom(userId) {
      var inRoom_data = {
        data: userId, //进入教室的学生id
        description: '学生进入教室', //自定义消息的说明字段
        action: 'inRoom'
      };
      this.tic.sendGroupCustomMessage(JSON.stringify(inRoom_data), function(res) {
        console.log('发送学生进入教室消息:', res);
      });
    },
    outRoom(userid) {
      var outRoom_data = {
        data: userid, //离开教室的学生id
        description: '学生离开教室', //自定义消息的说明字段
        action: 'outRoom',
        send: '系统'
      };
      this.tic.sendGroupCustomMessage(JSON.stringify(outRoom_data), function(res) {
        console.log('发送学生离开教室消息:', res);
      });
    },
    toggleDraw(item) {
      if (item.draw == 1) {
        item.draw = 0;
      } else {
        item.draw = 1;
      }
      if (item.draw) {
        var draw_data = {
          data: item.userCode, //学生id
          description: '给学生绘画权限', //自定义消息的说明字段
          action: 'toDraw',
          send: '系统'
        };
        this.tic.sendGroupCustomMessage(JSON.stringify(draw_data), function(res) {
          console.log('发送给学生绘画权限的消息:', res);
        });
      } else {
        var draw_data = {
          data: item.userCode, //学生id
          description: '去除学生绘画权限', //自定义消息的说明字段
          action: 'notDraw',
          send: '系统'
        };
        this.tic.sendGroupCustomMessage(JSON.stringify(draw_data), function(res) {
          console.log('去除学生绘画权限的消息:', res);
        });
      }
    },
    onStage(item, index = -1) {
      if (this.onStageNum < 15) {
        this.onStageNum++;
        item.action = 1;
        var onStage_data = {
          data: item.userCode, //上台学生id
          description: '邀请学生上台', //自定义消息的说明字段
          action: 'onStage',
          send: '系统'
        };
        this.tic.sendGroupCustomMessage(JSON.stringify(onStage_data), function(res) {
          console.log('发送上台消息:', res);
        });
      } else {
        this.$.ui.toast('上台人数已达15人上限');
      }
      if (index !== -1) {
        this.handsUpList.splice(index, 1);
      }
    },
    downStage(item, type = 0, index = -1) {
      this.onStageNum--;
      if (type == 1) {
        // 下台并将学生移除举手列表
        this.handsUpList.splice(index, 1);
      }
      item.speaking = false;
      item.draw = 0;
      item.action = 0;
      var downStage_data = {
        data: item.userCode, //下台学生id
        description: '请学生下台', //自定义消息的说明字段
        action: 'downStage',
        send: '系统'
      };
      this.tic.sendGroupCustomMessage(JSON.stringify(downStage_data), function(res) {
        console.log('发送下台消息:', res);
      });
    },
    // 踢出教室
    leaveRoom(item) {
      this.$confirm('您确定要将此学生移除教室吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '移除成功'
          });
          var leave_data = {
            data: item.userCode, // 踢出学生id
            description: '踢出学生消息', // 自定义消息的说明字段
            action: 'leaveRoom'
          };
          this.tic.sendGroupCustomMessage(JSON.stringify(leave_data), function(res) {
            console.log('踢出学生消息:', res);
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
    },
    // 举手消息
    handsUp() {
      var handsUp_data = {
        data: this.userId, // 举手学生id
        description: '学生举手消息', //自定义消息的说明字段
        action: 'handsUp'
      };
      this.tic.sendCustomMessage(this.teacher.userCode, JSON.stringify(handsUp_data), function(
        res
      ) {
        console.log('===sendCustomGroupMsg:', res);
      });
    },
    // 监听白板事件（按需监听）
    initBoardEvent() {
      var teduBoard = this.teduBoard;
      // 撤销状态改变
      teduBoard.on(TEduBoard.EVENT.TEB_OPERATE_CANUNDO_STATUS_CHANGED, enable => {
        this.canUndo = enable ? 1 : 0;
        console.log(
          '======================:  ',
          'TEB_OPERATE_CANUNDO_STATUS_CHANGED',
          enable ? '可撤销' : '不可撤销'
        );
      });

      // 重做状态改变
      teduBoard.on(TEduBoard.EVENT.TEB_OPERATE_CANREDO_STATUS_CHANGED, enable => {
        this.canRedo = enable ? 1 : 0;
        console.log(
          '======================:  ',
          'TEB_OPERATE_CANREDO_STATUS_CHANGED',
          enable ? '可���复' : '不可恢复'
        );
      });

      // 新增白板
      teduBoard.on(TEduBoard.EVENT.TEB_ADDBOARD, (boardIds, fid) => {
        console.log(
          '======================:  ',
          'TEB_ADDBOARD',
          ' boardIds:',
          boardIds,
          ' fid:',
          fid
        );
        this.proBoardData();
      });

      // 白���同步数据回调(收到该回调时需要将回调数据通过信令通道发送给房间内其他人，接受者收到后调用AddSyncData接口将数据添加到白板以实现数据同步)
      // TIC已经处理好了，可忽略该事件
      teduBoard.on(TEduBoard.EVENT.TEB_SYNCDATA, data => {
        console.log('======================:  ', 'TEB_SYNCDATA');
      });

      // 收到白板初始化完成事件���，表示白板已处于正常工作状态（此时白板为空白白板，历史数据尚未拉取完成）
      teduBoard.on(TEduBoard.EVENT.TEB_INIT, () => {
        console.log('======================:  ', 'TEB_INIT');
        // this.showMessageInBox('TIC', 'onTEBInit finished');
      });

      teduBoard.on(TEduBoard.EVENT.TEB_HISTROYDATA_SYNCCOMPLETED, () => {
        console.log('======================:  ', 'TEB_HISTROYDATA_SYNCCOMPLETED');
        // this.showMessageInBox('TIC', 'onTEBHistory Sync Completed finished');
      });

      // 白板错误回调
      teduBoard.on(TEduBoard.EVENT.TEB_ERROR, (code, msg) => {
        console.error('======================:  ', 'TEB_ERROR', ' code:', code, ' msg:', msg);
        // this.showMessageInBox('TIC', 'onTEBError code=' + code + ' msg:' + msg);
      });

      // 白板警告回调
      teduBoard.on(TEduBoard.EVENT.TEB_WARNING, (code, msg) => {
        console.error('======================:  ', 'TEB_WARNING', ' code:', code, ' msg:', msg);
        // this.showMessageInBox('TIC', 'onTEBWarning code=' + code + ' msg:' + msg);
      });

      // 图片状态加载回调
      teduBoard.on(TEduBoard.EVENT.TEB_IMAGE_STATUS_CHANGED, (status, data) => {
        console.log(
          '======================:  ',
          'TEB_IMAGE_STATUS_CHANGED',
          ' status:',
          status,
          ' data:',
          data
        );
      });

      // 删除白板页回调
      teduBoard.on(TEduBoard.EVENT.TEB_DELETEBOARD, (boardIds, fid) => {
        console.log(
          '======================:  ',
          'TEB_DELETEBOARD',
          ' boardIds:',
          boardIds,
          ' fid:',
          fid
        );
        this.proBoardData();
      });

      // 跳转白板页回调
      teduBoard.on(TEduBoard.EVENT.TEB_GOTOBOARD, (boardId, fid) => {
        console.log(
          '======================:  ',
          'TEB_GOTOBOARD',
          ' boardId:',
          boardId,
          ' fid:',
          fid
        );
        this.proBoardData();
      });

      // ppt动画步数改变回调
      teduBoard.on(TEduBoard.EVENT.TEB_GOTOSTEP, (step, count) => {
        console.log('======================:  ', 'TEB_GOTOSTEP', ' step:', step, ' count:', count);
      });

      // 增加H5动画PPT文件回调
      teduBoard.on(TEduBoard.EVENT.TEB_ADDH5PPTFILE, fid => {
        console.log('======================:  ', 'TEB_ADDH5PPTFILE', ' fid:', fid);
        this.proBoardData();
      });

      // 增加文件回调
      teduBoard.on(TEduBoard.EVENT.TEB_ADDFILE, fid => {
        console.log('======================:  ', 'TEB_ADDFILE', ' fid:', fid);
        this.proBoardData();
      });

      // 增加转码文件回调
      teduBoard.on(TEduBoard.EVENT.TEB_ADDTRANSCODEFILE, fid => {
        console.log('======================:  ', 'TEB_ADDTRANSCODEFILE', ' fid:', fid);
        this.proBoardData();
      });
      // 增加Images文件回调
      teduBoard.on(TEduBoard.EVENT.TEB_ADDIMAGESFILE, fid => {
        console.log('======================:  ', 'TEB_ADDIMAGESFILE', ' fid:', fid);
        this.proBoardData();
      });

      // 删除文件回调
      teduBoard.on(TEduBoard.EVENT.TEB_DELETEFILE, fid => {
        console.log('======================:  ', 'TEB_DELETEFILE', ' fid:', fid);
        this.proBoardData();
      });

      // 文件上传状态
      teduBoard.on(TEduBoard.EVENT.TEB_FILEUPLOADSTATUS, (status, data) => {
        console.log('======================:  ', 'TEB_FILEUPLOADSTATUS', status, data);
        if (status === 1) {
          this.showTip('上传成功');
        } else {
          this.showTip('上传失败');
        }
        document.getElementById('file_input').value = '';
      });

      // 切换文件回调
      teduBoard.on(TEduBoard.EVENT.TEB_SWITCHFILE, fid => {
        console.log('======================:  ', 'TEB_SWITCHFILE', ' fid:', fid);
        this.proBoardData();
      });

      // 上传背景图片的回调
      teduBoard.on(TEduBoard.EVENT.TEB_SETBACKGROUNDIMAGE, (fileName, fileUrl, userData) => {
        console.log(
          '======================:  ',
          'TEB_SETBACKGROUNDIMAGE',
          '  fileName:',
          fileName,
          '  fileUrl:',
          fileUrl,
          ' userData:',
          userData
        );
      });

      // 增加图片元素
      teduBoard.on(TEduBoard.EVENT.TEB_ADDIMAGEELEMENT, (fileName, fileUrl, userData) => {
        console.log(
          '======================:  ',
          'TEB_ADDIMAGEELEMENT',
          '  fileName:',
          fileName,
          '  fileUrl:',
          fileUrl,
          ' userData:',
          userData
        );
      });

      // 文件上传进度
      teduBoard.on(TEduBoard.EVENT.TEB_FILEUPLOADPROGRESS, data => {
        console.log('======================:  ', 'TEB_FILEUPLOADPROGRESS:: ', data);
        this.showTip('上传进度:' + parseInt(data.percent * 100) + '%');
      });

      // H5背景加载状态
      teduBoard.on(TEduBoard.EVENT.TEB_H5BACKGROUND_STATUS_CHANGED, (status, data) => {
        console.log(
          '======================:  ',
          'TEB_H5BACKGROUND_STATUS_CHANGED:: status:',
          status,
          '  data:',
          data
        );
      });

      // 转码进度
      teduBoard.on(TEduBoard.EVENT.TEB_TRANSCODEPROGRESS, res => {
        console.log('=======  TEB_TRANSCODEPROGRESS 转码进度：', JSON.stringify(res));
        if (res.code) {
          this.showErrorTip('转码失败code:' + res.code + ' message:' + res.message);
        } else {
          let status = res.status;
          if (status === 'ERROR') {
            this.showErrorTip('转码失败');
          } else if (status === 'UPLOADING') {
            this.showTip('上传中，当前进度:' + parseInt(res.progress) + '%');
          } else if (status === 'CREATED') {
            this.showTip('创建转码任务');
          } else if (status === 'QUEUED') {
            this.showTip('正在排队等待转码');
          } else if (status === 'PROCESSING') {
            this.showTip('转码中，当前进度:' + res.progress + '%');
          } else if (status === 'FINISHED') {
            this.showTip('转码完成');
            let config = {
              url: res.resultUrl,
              title: res.title,
              pages: res.pages,
              resolution: res.resolution
            };
            console.log('transcodeFile:', config);
            this.teduBoard.addTranscodeFile(config);
          }
        }
      });

      // 框选如果有选中内容则会触发此事件
      // teduBoard.on(TEduBoard.EVENT.TEB_RECTSELECTED, () => {
      //   this.teduBoard.clear(false, true); // 清空选中的内容
      // });

      // 监听增加元素事件
      teduBoard.on(TEduBoard.EVENT.TEB_ADDELEMENT, ({ id, userData }) => {
        console.log('id:', id, ' userData:', userData);
      });
      setTimeout(() => {
        this.teduBoard.setDrawEnable(true);
        this.teduBoard.setTextColor('#ff0000');
        // 画笔笔锋
        this.teduBoard.setHandwritingEnable(true);
        // 设置橡皮擦图标
        this.setEraserCursor();
        this.teduBoard.setRemoteCursorVisible(true);
      }, 1000);
      if (this.lessonStatus == 0) {
        this.isStartClass = true;
        this.post(`/live/lvb/room/startClass/${this.lessonId}`, {}, e => {
          if (e.code == 200) {
            // 上课推流
            // this.startRTC();
          } else {
            // this.$.ui.toast(e.msg);
          }
        });
      } else if (this.lessonStatus == 1) {
        this.isStartClass = true;
      }
    },
    // TRTC事件
    initTRTCEvent() {
      this.trtcClient.on('stream-added', event => {
        const remoteStream = event.stream;
        const remoteUserId = remoteStream.getUserId();
        console.log(
          'received a remoteStream ID: ' + remoteStream.getId() + ' from user: ' + remoteUserId
        );
        // 若需要观看该远端流，则需要订阅它，默认会自动订阅
        this.trtcClient.subscribe(remoteStream);
      });
      // 监听‘stream-removed’事件
      this.trtcClient.on('stream-removed', event => {
        // alert('stream-removed')
        const remoteStream = event.stream;
        console.log('remoteStream ID: ' + remoteStream.getId() + ' has been removed');
        // 停止播放并删除相应<video>标签
        remoteStream.stop();
        // document.getElementById(remoteStream.getId()).remove();
        let self = document.getElementById(remoteStream.getId());
        parent.removeChild(self);
        console.log('remoteStream.getId()', remoteStream.getId());
      });
      // 监听‘stream-updated’事件
      this.trtcClient.on('stream-updated', event => {
        const remoteStream = event.stream;
        console.log(
          'remoteStream ID: ' +
            remoteStream.getId() +
            ' was updated hasAudio: ' +
            remoteStream.hasAudio() +
            ' hasVideo: ' +
            remoteStream.hasVideo()
        );
      });

      // 监听‘stream-subscribed’事���
      this.trtcClient.on('stream-subscribed', event => {
        const remoteStream = (window.remoteStream = event.stream);
        // 远端流订阅成功，在HTML页面中创建一个<video>标签，假设该标签ID为‘remote-video-view’
        // 播放该远端流
        let remoteVideoWrapEl = document.createElement('div');
        remoteVideoWrapEl.id = remoteStream.getId();
        console.log('event.stream.userId_-----------------', event.stream.userId_);
        if (event.stream.userId_ == this.teacher.userCode) {
          document.querySelector('#video_teachers').insertBefore(remoteVideoWrapEl, null);
        } else {
          // remoteVideoWrapEl.id = 'video_student';
          let index;
          for (let i = 0; i < this.studentInRoom.length; i++) {
            if (this.studentInRoom[i].userCode == event.stream.userId_) {
              index = i;
              break;
            }
          }
          console.log('匹配到的index', index);
          let doc = document.querySelectorAll('.s-item-active');
          doc[index].querySelector('#video_student').insertBefore(remoteVideoWrapEl, null);
        }
        remoteStream.play(remoteVideoWrapEl).catch(e => {
          const errorCode = e.getCode();
          if (errorCode === 0x4043) {
            // safari浏览器限制播放必须手动触发，引导用户手势操作恢复�����视频播放
            this.$confirm('请您对音视频播放进行授权', '提示', {
              confirmButtonText: '授权',
              type: 'warning'
            }).then(() => {
              remoteStream.resume();
            });
          }
        });
      });

      this.trtcClient.on('connection-state-changed', event => {
        console.log('connection-state-changed:', event.state);
      });

      this.trtcClient.on('peer-join', event => {
        console.log('peer-join', event);
        const userId = event.userId;
      });

      this.trtcClient.on('peer-leave', event => {
        console.log('peer-leave', event);
        const userId = event.userId;
      });

      this.trtcClient.on('mute-audio', event => {
        console.log('mute-audio', event);
        const userId = event.userId;
        if (userId == this.teacher.userCode) {
          this.teacher.speaking = false;
        } else {
          for (let i = 0; i < this.studentInRoom.length; i++) {
            if (userId == this.studentInRoom[i].userCode) {
              this.studentInRoom[i].speaking = false;
              break;
            }
          }
        }
        this.showTip(`${userId}关闭了麦克风`);
      });

      this.trtcClient.on('mute-video', event => {
        console.log('mute-video', event);
        const userId = event.userId;
        if (userId == this.teacher.userCode) {
          this.teacher.action = 0;
        }
        this.showTip(`${userId}关闭了摄像头`);
      });

      this.trtcClient.on('unmute-audio', event => {
        console.log('unmute-audio', event);
        const userId = event.userId;
        if (userId == this.teacher.userCode) {
          this.teacher.speaking = true;
        } else {
          for (let i = 0; i < this.studentInRoom.length; i++) {
            if (userId == this.studentInRoom[i].userCode) {
              this.studentInRoom[i].speaking = true;
              break;
            }
          }
        }
        this.showTip(`${userId}打开了麦克风`);
      });

      this.trtcClient.on('unmute-video', event => {
        console.log('unmute-video', event);
        const userId = event.userId;
        if (userId == this.teacher.userCode) {
          this.teacher.action = 1;
        }
        this.showTip(`${userId}打开了摄像头`);
      });

      this.trtcClient.on('error', error => {
        console.error('client error observed: ' + error);
        const errorCode = error.getCode();
        // 根据ErrorCode列表查看详细错误原因。
      });
    },

    /**
     * 增加IM消息监听回调
     */
    addTICMessageListener() {
      this.tic.addTICMessageListener({
        /**
         * 收到C2C文本消息
         * @param fromUserId		发送此消息的用户id
         * @param text				收到消息的内容
         * @param textLen			收到消息的长度
         */
        onTICRecvTextMessage: (fromUserId, text, textLen) => {
          // this.showMessageInBox(fromUserId, text);
          if (datas.action == 'pazzle') {
            // 接收到学生问题答疑
            console.log('接收到学生问题答疑');
            this.receiveStuQues(fromUserId, datas);
          }
        },

        /**
         * 收到C2C自定义消息
         * @param fromUserId		发送此消息的用户id
         * @param data				收到消息的内容
         * @param dataLen			收到消息的长度
         */
        onTICRecvCustomMessage: (fromUserId, data, textLen) => {
          // this.showMessageInBox(fromUserId, data);
          console.log(data, '接收到C2C自定义消息');
          // 收到 c2c 消息
          let datas = JSON.parse(data.data);
          // 举手消息
          if (datas.action == 'handsUp') {
            this.receiveHandsUp(fromUserId);
          } else if (datas.action == 'handsDown') {
            this.receiveHandsDown(fromUserId);
          } else if (datas.action == 'pazzle') {
            // 接收到学生问题答疑
            console.log('接收到学生问题答疑');
            this.receiveStuQues(fromUserId, datas);
          }
        },

        /**
         * 收到群文本消息
         * @param fromUserId		发送此消息的用户id
         * @param text				收到消息的内容
         * @param textLen			收到消息的长度
         */
        onTICRecvGroupTextMessage: (fromUserId, text, textLen) => {
          console.log(text, 'text');
          const datas = JSON.parse(text);
          // if (!datas.hasOwnProperty('action')) {
          //   this.showMessageInBox(fromUserId, text);
          // }
          // this.showMessageInBox(fromUserId, text);

          console.log('收到群文本消息-------------------------', datas);
          if (datas.action == 'question') {
            // 后台发送的IM
            console.log('接收到开始答题的信息--------------');
            this.questionId = datas.code;
            if (this.role == 1) {
              if (datas.type == 3) {
                this.quesTypeIndex = 1;
              } else {
                this.optionList = [];
                this.quesTypeIndex = 0;
                const options = datas.questionOptions.split('');
                console.log('options------', options);
                options.forEach(item => {
                  const obj = {};
                  obj.value = item;
                  obj.isChoose = 0;
                  this.optionList.push(obj);
                });
                console.log('this.optionList', this.optionList);
              }
              // 打开答题器
              this.showResponder = true;
            }
          } else if (datas.action == 'pazzle') {
            // 接收到学生问题答疑
            console.log('接收到学生问题答疑');
            this.receiveStuQues(fromUserId, datas);
          } else if (datas.action == 'studentExit') {
            let userId = datas.studentIds;
            console.log(`接收到${userId}离开教室消息`);
            console.log('this.studentInRoom-0', this.studentInRoom);
            console.log('this.studentList-0', this.studentList);
            for (let i = 0; i < this.allStudentList.length; i++) {
              if (this.allStudentList[i].userCode == userId) {
                this.studentList.push(this.allStudentList[i]);
                break;
              }
            }
            // for (let i = 0; i < this.studentInRoom.length; i++) {
            //   if (this.studentInRoom[i].userCode == userId) {
            //     this.studentList.push(...this.studentInRoom.splice(i, 1))
            //     break
            //   }
            // }
            console.log('this.studentInRoom-1', this.studentInRoom);
            console.log('this.studentList-1', this.studentList);
          }
        },
        /**
         * 收到群自定义消息
         * @param fromUserId		发送此消息的用户id
         * @param data				收到消息的内容
         * @param dataLen			收到消息的长度
         */
        onTICRecvGroupCustomMessage: (fromUserId, data, textLen) => {
          // this.showMessageInBox(fromUserId, data);
          // TODO 收到群消息
          console.log('-------------------------', data);
          let datas = JSON.parse(data);
          // action: 'newMsg',
          // sender: this.nickName,
          // info: this.imMsg.common.data
          if (datas.action == 'newMsg') {
            this.hasNewMsg = true;
            var d = new Date();
            var time = `${('0' + d.getHours()).substr(-2)}:${('0' + d.getMinutes()).substr(-2)}:${(
              '0' + d.getSeconds()
            ).substr(-2)}.${('00' + d.getMilliseconds()).substr(-3)}`;
            var type = '1';
            var nickName = '';
            var avatar = '';
            var text = datas.info;
            for (let i = 0; i < this.studentInRoom.length; i++) {
              if (this.studentInRoom[i].userCode == fromUserId) {
                nickName = this.studentInRoom[i].nickName;
                avatar = this.studentInRoom[i].avatar;
                break;
              }
            }
            if (this.teacher.userCode == fromUserId) {
              nickName = this.teacher.nickName;
              avatar = this.teacher.avatar;
            }
            this.msgs.push({
              time: time,
              send: fromUserId,
              content: text,
              type: type,
              nickName: nickName,
              avatar: avatar
            });
            console.log(this.msgs, '-------------------------this.msgs');
            this.$nextTick(() => {
              var msgbox = document.querySelector('#msg_box');
              msgbox.scrollTop = msgbox.scrollHeight;
            });
          } else if (datas.action == 'inRoom') {
            let userId = datas.data;
            // for (let i = 0; i < this.studentList.length; i++) {
            //   if (this.studentList[i].userCode == userId) {
            //     this.studentInRoom.push(...this.studentList.splice(i, 1))
            //     break
            //   }
            // }
            let inList = false;
            for (let i = 0; i < this.studentInRoom.length; i++) {
              if (this.studentInRoom[i].userCode == userId) {
                inList = true;
              }
            }
            if (inList == false) {
              for (let i = 0; i < this.allStudentList.length; i++) {
                if (this.allStudentList[i].userCode == userId) {
                  this.studentInRoom.push(this.allStudentList[i]);
                  for (let i = 0; i < this.studentList.length; i++) {
                    if (this.studentList[i].userCode == userId) {
                      this.studentList.splice(i, 1);
                      break;
                    }
                  }
                  console.log('添加学生到在线列表', this.studentInRoom);
                  break;
                }
              }
            }
            console.log('this.allStudentList', this.allStudentList);
            console.log('this.studentList', this.studentList);
            console.log(`接收到${userId}进入教室消息`, this.studentInRoom);
            // 同步聊天禁言状态
            var changeChat_data = {
              data: this.banned,
              description: '全员禁言消息', //自定义消息的说明字段
              action: 'changeChat'
            };
            this.tic.sendGroupCustomMessage(JSON.stringify(changeChat_data), function(res) {
              console.log('发送禁言状态的消息:', res);
            });
            // 当前用户是老师向学生发送IM消息同步更新已上课时间
            if (this.teacher.userCode == this.userId) {
              let obj = {
                classHour: this.classHour,
                classMinute: this.classMinute,
                classSecond: this.classSecond
              };
              var classTime_data = {
                data: obj, // 已上课时间
                description: '已上课时间', //自定义消息的说明字段
                action: 'ClassTime'
              };
              this.tic.sendGroupCustomMessage(JSON.stringify(classTime_data), function(res) {
                console.log('===sendCustomGroupMsg:', res);
              });
            }
          } else if (datas.action == 'outRoom') {
            let userId = datas.data;
            console.log(`接收到${userId}离开教室消息`);
            console.log('this.studentInRoom-0', this.studentInRoom);
            console.log('this.studentList-0', this.studentList);
            // for (let i = 0; i < this.studentList.length; i++) {
            //   if (this.studentList[i].userCode == userId) {
            //     this.studentList.push(...this.studentInRoom.splice(i, 1))
            //     break;
            //   }
            // }
            for (let i = 0; i < this.studentInRoom.length; i++) {
              if (this.studentInRoom[i].userCode == userId) {
                this.studentList.push(...this.studentInRoom.splice(i, 1));
                break;
              }
            }
            console.log('this.studentInRoom-1', this.studentInRoom);
            console.log('this.studentList-1', this.studentList);
          } else if (datas.action == 'onStage') {
            let userId = datas.data;
            console.log('接收到上台消息');
            let curUserId = this.userId;
            // 如果是当前用户
            if (curUserId == userId) {
              this.cameraEnable = true;
              this.micEnable = true;
            }
            for (let i = 0; i < this.studentInRoom.length; i++) {
              if (this.studentInRoom[i].userCode == userId) {
                // 上台状态
                this.studentInRoom[i].action = 1;
                this.cameraEnable = true;
                this.micEnable = true;
                break;
              }
            }
            console.log('接收到上台消息', this.studentInRoom[i]);
          } else if (datas.action == 'downStage') {
            console.log('接收到下台消息');
            let userId = datas.data;
            let curUserId = this.userId;
            // 如果是当前用户
            if (curUserId == userId) {
              this.cameraEnable = false;
              this.micEnable = false;
              this.boardEnable = false;
              this.drawEnable = false;
              this.teduBoard.setDrawEnable(false);
              this.stopPush(0);
            }
            for (let i = 0; i < this.studentInRoom.length; i++) {
              if (this.studentInRoom[i].userCode == userId) {
                // 下台状态
                this.studentInRoom[i].action = 0;
                this.studentInRoom[i].draw = 0;
                this.studentInRoom[i].speaking = 0;
                break;
              }
            }
            console.log('接收到下台消息');
          } else if (datas.action == 'toDraw') {
            let userId = datas.data;
            console.log('接收到绘画权限消息');
            let curUserId = this.userId;
            // 如果是当前用户
            if (curUserId == userId) {
              this.boardEnable = true;
              this.drawEnable = true;
              this.teduBoard.setDrawEnable(true);
            }
            console.log('接收到绘画权限消息');
          } else if (datas.action == 'notDraw') {
            let userId = datas.data;
            console.log('接收到去除绘画权限消息');
            let curUserId = this.userId;
            // 如果是当前用户
            if (curUserId == userId) {
              this.boardEnable = false;
              this.drawEnable = false;
              this.teduBoard.setDrawEnable(false);
            }
            console.log('接收到去除绘画权限消息');
          } else if (datas.action == 'startAnswer') {
            console.log('接收到开始答题的信息');
            let obj = datas.data;
            this.quesTypeIndex = obj.quesTypeIndex;
            this.rightAnswer = obj.rightOption;
            this.optionList = obj.optionList;
            // 打开答题器
            this.showResponder = true;
          } else if (datas.action == 'stopAnswer') {
            console.log('接收到结束答题的信息');
            this.curJuage = -1;
            this.showResponder = false;
            this.stuDisableSubmit = false;
            this.optionList = [
              { value: 'A', isChoose: 0 },
              { value: 'B', isChoose: 0 },
              { value: 'C', isChoose: 0 },
              { value: 'D', isChoose: 0 }
            ];
            this.rightOption = [];
          } else if (datas.action == 'question') {
            // 后台发送的IM
            console.log('接收到开始答题的信息');
            let obj = datas.data;
            if (obj.questionType == 3) {
              this.quesTypeIndex = 1;
            } else {
              this.quesTypeIndex = 0;
            }
            // 待做 等待接收到im消息
            this.rightAnswer = obj.rightOption;
            this.optionList = obj.optionList;
            // 打开答题器
            this.showResponder = true;
          } else if (datas.action == 'ClassTime') {
            const obj = datas.data;
            this.classHour = obj.classHour;
            this.classMinute = obj.classMinute;
            this.classSecond = obj.classSecond + 1;
            this.classTime =
              this.toDub(this.classHour) +
              ':' +
              this.toDub(this.classMinute) +
              ':' +
              this.toDub(this.classSecond);
          }
        },
        /**
         * 所有消息
         * @param msg	IM消息体
         * @note 所有收到的消息都会在此回调进行通知，包括前面已经封装的文本和自定义消息（白板信令消息除外）
         */
        onTICRecvMessage(msg) {}
      });
    },

    // 事件监听回调
    addTICEventListener() {
      this.tic.addTICEventListener({
        onTICMemberJoin: members => {
          // members:用户id
          // this.showMessageInBox('系统消息', members.join(',') + '进入课堂');
          console.log('------------!!!!---------------进入', members);
          console.log('members[0]', members);
        },
        onTICMemberQuit: members => {
          // this.showMessageInBox('系统消息', members.join(',') + '退出课堂');
          this.studentInRoom.forEach((item, index) => {
            if (item.userCode === members[0]) {
              this.studentInRoom.splice(index, 1);
            }
          });
        },

        onTICClassroomDestroy: () => {
          this.quitClassroom();
          this.showTip(`老师解散了课堂`);
        },

        onTICTrtcClientCreated: () => {
          // this.showTip(`trtcclient创建成功`);
          window.trtcClient = this.trtcClient = this.tic.getTrtcClient();
          this.initTRTCEvent();
        }
      });
    },

    // IM状态监听回调
    addTICStatusListener() {
      this.tic.addTICStatusListener({
        onTICForceOffline: event => {
          if (event.data) {
            if (event.data.type) {
              if (event.data.type == window.TIM.TYPES.KICKED_OUT_MULT_ACCOUNT) {
                this.showErrorTip(`同一账号，多页面登录被踢`);
              } else if (event.data.type == window.TIM.TYPES.KICKED_OUT_MULT_DEVICE) {
                this.showErrorTip(`同一账号，多端登录被踢`);
              } else if (event.data.type == window.TIM.TYPES.KICKED_OUT_USERSIG_EXPIRED) {
                this.showErrorTip(`userSig过期`);
              } else {
                this.showErrorTip(`帐号其他地方登录，被T了`);
              }
            } else {
              this.showErrorTip(`帐号其他地方登录，被T了`);
            }
          } else {
            this.showErrorTip(`帐号其他地方登录，被T了`);
          }
          this.status = this.STATUS_UNLOGIN;
          this.clearClassInfo();
          // this.showMessageInBox('TIC', 'onTICForceOffline ' + this.roomID);
        }
      });
    },

    /**
     * 结束推流
     */
    stopPush(type = 0, userId = '', callback) {
      if (type == 0) {
        // type: 0 关闭本地摄像头
        if (this.localStream && this.isPushing) {
          this.trtcClient.unpublish(this.localStream).then(() => {
            this.isPushing = 0;
            // 摄像头关闭
            this.cameraState = 0;
            // 麦克关闭
            this.micState = 0;
            this.teacher.speaking = false;
            if (this.userId == this.teacher.userCode) {
              this.teacher.action = 0;
            }
            document.getElementById('local_video').remove();
            this.localStream.stop();
            this.localStream = null;
          });
        }
      } else {
        // 关闭他人摄像头
        let index;
        for (let i = 0; i < this.studentInRoom.length; i++) {
          if (this.studentInRoom[i].userCode == userId) {
            index = i;
            break;
          }
        }
        let doc = document.querySelectorAll('.s-item-active');
        doc[index].querySelector('#video_student').remove();
      }
      if (Object.prototype.toString.call(callback) === '[object Function]') {
        callback();
      }
    },

    // 启动推流(推摄像头)
    startRTC() {
      this.teacher.speaking = true;
      this.isPushing = 1;
      // 摄像头开启状态
      this.cameraState = 1;
      // 麦克开启
      this.micState = 1;
      if (this.userId == this.teacher.userCode) {
        this.teacher.action = 1;
      }
      // 从麦克风和摄像头采集本地音视频流
      let cameraStream = TRTC.createStream({
        audio: true,
        video: true
      });
      // 设置视频分辨率等参数
      cameraStream.setVideoProfile('720p');
      if (this.localStream && this.isPushing) {
        // 如果正在推流, 先停止发布流
        this.stopPush(0, 0, () => {
          this.publishLocalStream(cameraStream);
        });
      } else {
        this.publishLocalStream(cameraStream);
      }
    },

    /**
     * 推屏幕分享
     */
    pushScreen() {
      // 从麦克风和摄像头采集本地音视频流
      let screenStream = TRTC.createStream({
        audio: true,
        screen: true
      });

      // 设置视频分辨率等参数
      screenStream.setScreenProfile({
        width: 1920,
        height: 1080,
        frameRate: 15,
        bitrate: 1600 /* kbps */
      });
      if (this.localStream && this.isPushing) {
        // 如果正在推流, 先停止发布流
        this.stopPush(0, 0, () => {
          this.publishLocalStream(screenStream);
        });
      } else {
        this.publishLocalStream(screenStream);
      }
    },
    // 摄像头
    publishLocalStream(localStream) {
      window.localStream = localStream;
      localStream
        .initialize()
        .catch(error => {
          console.error('failed initialize localStream ' + error);
        })
        .then(() => {
          var localVideoWrapEl = document.getElementById('local_video');
          if (!localVideoWrapEl) {
            localVideoWrapEl = document.createElement('div');
            localVideoWrapEl.id = 'local_video';
            if (this.role == 0 && this.userId == this.teacher.userCode) {
              document.querySelector('#video_teachers').insertBefore(localVideoWrapEl, null);
            } else {
              localVideoWrapEl.id = 'local_video';
              let index;
              for (let i = 0; i < this.studentInRoom.length; i++) {
                if (this.studentInRoom[i].userCode == this.userId) {
                  index = i;
                  break;
                }
              }
              let doc = document.querySelectorAll('.s-item-active');
              doc[index].querySelector('#video_student').insertBefore(localVideoWrapEl, null);
            }
          }
          // 本地流播放
          localStream.play(localVideoWrapEl, {
            muted: true
          });

          // 发布本地流（远端可收到）
          this.trtcClient &&
            this.trtcClient
              .publish(localStream)
              .then(() => {
                // 本地流发布成功
                this.isPushing = 1; // 正在推流
                this.isPushCamera = true; // 正在推摄像头
                this.localStream = localStream;
                this.showTip('推流成功');
              })
              .catch(error => {
                console.log(error);
              });
        })
        .catch(error => {
          this.showErrorTip(`获取本地流失败, ${JSON.stringify(error)}`);
        });
    },
    /**
     * 摄像头开关
     */
    toggleCamera() {
      if (this.cameraState == 0 && this.micState == 0 && this.isPushing == 0) {
        this.startRTC();
        this.enableCamera = !this.enableCamera;
        this.enableMic = !this.enableMic;
      } else {
        this.enableCamera = !this.enableCamera;
        if (this.enableCamera) {
          this.cameraState = 1;
        } else {
          this.cameraState = 0;
        }
        if (this.localStream) {
          this.enableCamera ? this.localStream.unmuteVideo() : this.localStream.muteVideo();
        }
      }
    },

    /**
     * 麦克风开关
     */
    toggleMic() {
      if (this.cameraState == 0 && this.micState == 0 && this.isPushing == 0) {
        this.startRTC();
        this.enableCamera = !this.enableCamera;
        this.enableMic = !this.enableMic;
      } else {
        this.enableMic = !this.enableMic;
        if (this.enableMic) {
          this.micState = 1;
          this.teacher.speaking = true;
        } else {
          this.micState = 0;
          this.teacher.speaking = false;
        }
        if (this.localStream) {
          this.enableMic ? this.localStream.unmuteAudio() : this.localStream.muteAudio();
        }
      }
    },

    /**
     * 枚举摄像头
     */
    getCameraDevices() {
      var WebRTC = this.tic.getWebRTCInstance();
      WebRTC.getVideoDevices(devices => {
        this.devices.camera = devices;
      });
    },

    /**
     * 枚举麦克风
     */
    getMicDevices() {
      var WebRTC = this.tic.getWebRTCInstance();
      WebRTC.getAudioDevices(devices => {
        this.devices.mic = devices;
      });
    },

    /**
     * 发送普通文本消息
     */
    sendMsg() {
      if (!this.imMsg.common.data) {
        this.showErrorTip(`不能发送空消息`);
        return;
      }
      var text = this.imMsg.common.data;
      // 群组 文本
      if (!this.roomID) {
        this.showErrorTip('发送群消息时，房间号为空');
        return;
      }
      // var newMsg_data = {
      //   description: '新消息', // 自定义消息的说明字段
      //   action: 'newMsg'
      // };
      // this.tic.sendGroupCustomMessage(JSON.stringify(newMsg_data), function(res) {
      //   console.log('发送聊天消息:', res);
      // });
      this.tic.sendGroupTextMessage(text, res => {
        if (res.code !== 0) {
          this.showMessageInBox(
            'TIC',
            'sendGroupTextMessage failed, code=' + res.code + ' content:' + text
          );
        } else {
          this.showMessageInBoxs(this.userId, text);
        }
        console.log('===sendTextMessage:', res);
      });
      console.log(this.msgs, '————————————————————msg————————————————————');
      this.imMsg.common.data = '';
    },

    /**
     * 发送自定义消息
     */
    sendCustomGroupMsg() {
      if (this.imMsg.common.data.trim() == '') {
        this.showErrorTip(`不能发送空消息`);
        return;
      } else {
        //群组 自定义
        // TODO 发送
        var a = {
          description: '新消息',
          action: 'newMsg',
          sender: this.nickName,
          info: this.imMsg.common.data
        };
        var text = JSON.stringify(a);
        var that = this;
        this.tic.sendGroupCustomMessage(text, res => {
          this.showMessageInBoxs(this.userId, text);
        });
      }
    },

    /**
     * 设置当前页背景色
     * @param {*} color Hex 色值，如 #ff00ff
     */
    onSetBackgroudColor(color) {
      this.backgroundColor = this.rgbToHex(color);
      this.teduBoard.setBackgroundColor(this.backgroundColor);
    },

    /**
     * 设置涂鸦颜色
     */
    onSetBrushColor(index) {
      this.curColor = index;
      this.brushColor = this.colorBar[index];
      this.teduBoard.setBrushColor(this.brushColor);
    },

    rgbToHex(color) {
      var arr = [],
        strHex;
      if (/^(rgb|RGB)/.test(color)) {
        arr = color.replace(/(?:\(|\)|rgb|RGB)*/g, '').split(',');
        strHex =
          '#' +
          ((1 << 24) + (arr[0] << 16) + (arr[1] << 8) + parseInt(arr[2])).toString(16).substr(1);
      } else {
        strHex = color;
      }
      return strHex;
    },

    /**
     * 设置涂鸦粗细
     * @param {*} num
     */
    // 设置画笔粗细
    onSetThin(num) {
      this.teduBoard.setBrushThin(num);
    },

    /**
     * 清空当前页涂鸦(保留背景色/图片)
     */
    onclearDraws() {
      this.teduBoard.clear();
    },

    /**
     * 清空当前页涂鸦 + 背景色/图片
     */
    onClear() {
      this.teduBoard.clear(true);
    },

    // 回退
    onUndo() {
      this.teduBoard.undo();
    },

    // 重做
    onRedo() {
      this.teduBoard.redo();
    },

    /**
     * 上传文件
     */
    uploadFile() {
      var file = document.getElementById('file_input').files[0];
      if (/\.(bmp|jpg|jpeg|png|gif)/i.test(file.name)) {
        // this.teduBoard.setBackgroundImage({
        //   data: file,
        //   userData: 'image'
        // });
        this.teduBoard.addImageElement({
          data: file,
          userData: 'image'
        });
      } else {
        this.teduBoard.applyFileTranscode(
          {
            data: file,
            userData: 'tiw'
          },
          {
            minResolution: '960x540',
            isStaticPPT: false,
            thumbnailResolution: '200x200'
          }
        );
      }
      document.getElementById('file_input').value = null;
    },

    onAddH5File(url) {
      this.teduBoard.addH5File(url);
    },

    onAddVideoFile(url) {
      this.teduBoard.addVideoFile(url);
    },

    onAddImageElement(url) {
      console.log('添加图片白板执行了');
      this.teduBoard.addImageElement(url);
    },

    onAddH5Element() {
      if (this.h5Url) {
        this.teduBoard.addH5Element(this.h5Url);
      }
    },

    // 动画上一步
    prevStep() {
      this.teduBoard.prevStep();
    },

    // 动画下一步
    nextStep() {
      this.teduBoard.nextStep();
    },

    /**
     * 上一页
     */
    prevBoard() {
      this.teduBoard.prevBoard();
    },

    /**
     * 下一页
     */
    nextBoard() {
      this.teduBoard.nextBoard();
    },

    /**
     * 新增一页
     */
    addBoard() {
      this.teduBoard.addBoard();
    },

    /**
     * 删除当前页
     */
    onDeleteBoard(boardId) {
      this.teduBoard.deleteBoard(boardId);
    },

    /**
     * 白板事件回调处理
     * @param {*} data
     */
    proBoardData(data) {
      this.fileInfoList = this.teduBoard.getFileInfoList();
      this.currentFileId = this.teduBoard.getCurrentFile();
      this.thumbUrls = this.teduBoard.getThumbnailImages(this.currentFileId);
      var fileInfo = this.teduBoard.getFileInfo(this.currentFileId);
      if (fileInfo) {
        this.boardData = {
          currentBoardId: this.currentFileId,
          boardIdlist: this.teduBoard.getFileBoardList(this.currentFileId),
          current: fileInfo.currentPageIndex + 1,
          total: fileInfo.pageCount
        };
      }
    },

    onSwitchFile(file) {
      this.teduBoard.switchFile(file.fid);
    },

    onDeleteFile(file) {
      this.onDeleteFileById(file.fid);
    },

    onDeleteFileById(fid) {
      if (fid == '#DEFAULT') {
        this.showErrorTip('默认白板不能删除!');
        // this.showMessageInBox('TIC ：', '#DEFAULT can not be deleted');
        return;
      }
      this.teduBoard.deleteFile(fid);
    },

    onClickBoardTab(targetTab) {
      var fid = targetTab.name;
      if (fid == '#DEFAULT') {
        this.openThumb = false;
      } else {
        this.openThumb = true;
      }
      this.teduBoard.switchFile(fid);
    },

    onThumbClick(index) {
      this.curThumb = index;
      var fileId = this.teduBoard.getCurrentFile();
      var boardList = this.teduBoard.getFileBoardList(fileId);
      var boardId = boardList[index];
      this.teduBoard.gotoBoard(boardId);
    },
    //Board(涂鸦操作)
    onSetDrawEnable() {
      this.teduBoard.setDrawEnable(this.drawEnable);
    },

    // onSetTextSize(size) {
    //   this.teduBoard.setTextSize(size);
    // },
    onSetTextColor(index) {
      this.curFontColor = index;
      this.textColor = this.colorBar[index];
      this.teduBoard.setTextColor(this.textColor);
    },

    onSetBackgroundImage(backgroundImage) {
      var path = backgroundImage;
      this.teduBoard.setBackgroundImage(path);
    },

    onSetBackgroundH5(backgroundImageH5) {
      var path = backgroundImageH5;
      this.teduBoard.setBackgroundH5(path);
    },

    onGotoBoard(boardId) {
      this.teduBoard.gotoBoard(boardId, false);
    },

    onSetScale(scale) {
      this.teduBoard.setBoardScale(scale);
    },

    //--------------util-----------------
    showErrorTip(title, time) {
      this.$message({
        showClose: true,
        message: title,
        type: 'error'
      });
    },

    showTip(title, time) {
      this.$message({
        showClose: true,
        message: title,
        type: 'success'
      });
    },

    showMessageInBox(fromUserId, text) {
      var d = new Date();
      var time = `${('0' + d.getHours()).substr(-2)}:${('0' + d.getMinutes()).substr(-2)}:${(
        '0' + d.getSeconds()
      ).substr(-2)}.${('00' + d.getMilliseconds()).substr(-3)}`;
      var type = '1';
      var nickName = '';
      var avatar = '';
      for (let i = 0; i < this.studentInRoom.length; i++) {
        if (this.studentInRoom[i].userCode == fromUserId) {
          nickName = this.studentInRoom[i].nickName;
          avatar = this.studentInRoom[i].avatar;
          break;
        }
      }
      if (this.teacher.userCode == fromUserId) {
        nickName = this.teacher.nickName;
        avatar = this.teacher.avatar;
      }
      this.msgs.push({
        time: time,
        send: fromUserId,
        content: text,
        type: type,
        nickName: nickName,
        avatar: avatar
      });
      console.log(this.msgs, '-------------------------this.msgs');
      this.$nextTick(() => {
        var msgbox = document.querySelector('#msg_box');
        msgbox.scrollTop = msgbox.scrollHeight;
      });
    },
    showMessageInBoxs(fromUserId, text) {
      var d = new Date();
      var time = `${('0' + d.getHours()).substr(-2)}:${('0' + d.getMinutes()).substr(-2)}`;
      var type = '2';
      var nickName = '';
      var avatar = '';
      var datas = JSON.parse(text);
      for (let i = 0; i < this.studentInRoom.length; i++) {
        if (this.studentInRoom[i].userCode == fromUserId) {
          nickName = this.studentInRoom[i].nickName;
          avatar = this.studentInRoom[i].avatar;
          break;
        }
      }
      if (this.teacher.userCode == fromUserId) {
        nickName = this.teacher.nickName;
        avatar = this.teacher.avatar;
      }
      this.msgs.push({
        time: time,
        send: fromUserId,
        content: datas.info,
        type: type,
        nickName: nickName,
        avatar: avatar
      });
      this.imMsg.common.data = '';
      this.$nextTick(() => {
        var msgbox = document.querySelector('#msg_box');
        msgbox.scrollTop = msgbox.scrollHeight;
      });
    },
    testFontColor(e) {
      // e.preventDefault();
    },
    // 老师点击举手按钮
    clickAsk(index) {
      this.curLive = index;
    },
    // 学生点击举手按钮
    stuClickAsk(index) {
      this.handsUp();
      this.curLive = index;
    },
    // 点击答疑
    clickQuestion(index) {
      this.showQuestion = true;
      this.curLive = index;
      if (this.role == 0) {
        this.getQuestionList();
      }
    },
    // 获取问题答疑列表
    getQuestionList() {
      this.get('/live/lvb/pazzle/pazzleList', { lessonId: this.lessonId, resolveFlag: 'n' }, e => {
        if (e.code == 200) {
          this.stuQuesImgList = e.data;
          if (this.stuQuesImgList.length == 0) {
            this.isStuAskQues = false;
          } else {
            this.isStuAskQues = true;
          }
        } else {
          this.$.ui.toast(e.msg);
        }
      });
      this.get('/live/lvb/pazzle/pazzleList', { lessonId: this.lessonId, resolveFlag: 'y' }, e => {
        if (e.code == 200) {
          this.stuQuesImgListF = e.data;
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    // 点击麦克风
    clickMicrop(index) {
      this.curLive = index;
    },
    // 点击答题按钮
    clickAnswer(index) {
      this.showResponder = true;
      this.curLive = index;
    },
    // 点击录制
    clickRecord(index) {
      this.curLive = index;
    },
    // 接收到学生举手的消息
    receiveHandsUp(id) {
      let index = -1;
      for (let i = 0; i < this.handsUpList.length; i++) {
        if (this.handsUpList[i].userCode == id) {
          index = i;
          break;
        }
      }
      if (index == -1) {
        for (let i = 0; i < this.studentInRoom.length; i++) {
          if (this.studentInRoom[i].userCode == id) {
            this.handsUpList.push(this.studentInRoom[i]);
            break;
          }
        }
      }
    },
    // 接收到学生取消举手的消息
    receiveHandsDown(id) {
      let index = -1;
      for (let i = 0; i < this.handsUpList.length; i++) {
        if (this.handsUpList[i].userCode == id) {
          index = i;
          break;
        }
      }
      if (index !== -1) {
        this.handsUpList.splice(index, 1);
      }
    },
    // 接收到学生答疑问题详情
    receiveStuQues(id, data) {
      console.log(data, '------==========');
      this.isStuAskQues = true;
      // this.stuQuesImgList.push(...data.data);
    },
    // 选择判断题的正确选项
    onChooseJuage(index) {
      this.curJuage = index;
    },
    // 老师点击开始答题，发送通信 题型，选项列表，正确选项
    teacherStartAnswer() {
      // 开始计时
      this.timeStart();
      // 切换到答题详情页
      this.responderType = 1;
      let rightAnswer = '';
      let questionOptions = '';
      let questionType = '';
      if (this.quesTypeIndex == 0) {
        // 选择题
        if (this.rightOption.length == 1) {
          questionType = 1;
          // 单选题
          rightAnswer = this.rightOption[0].value;
          this.optionList.forEach(item => {
            questionOptions = questionOptions + item.value;
          });
        } else {
          //多选题
          questionType = 2;
          this.rightOption.forEach(item => {
            rightAnswer = rightAnswer + item.value;
          });
          this.optionList.forEach(item => {
            questionOptions = questionOptions + item.value;
          });
        }
        this.questionAnswer = rightAnswer;
      } else if (this.quesTypeIndex == 1) {
        // 判断题
        questionType = 3;
        rightAnswer = String(this.curJuage);
        if (this.curJuage == 0) {
          this.questionAnswer = '错误';
        } else {
          this.questionAnswer = '正确';
        }
      }
      // 1单选 2多选 3判断
      this.post(
        '/live/lvb/question/question',
        {
          lessonId: this.lessonId,
          questionAnswer: rightAnswer,
          questionOptions: questionOptions,
          questionType: questionType
        },
        e => {
          if (e.code == 200) {
            this.$.ui.toast('问题已发送');
          } else {
            this.$.ui.toast(e.msg);
          }
        }
      );
      // 轮询请求学生答题统计
      this.startRecAns();
      // 开始轮询请求学生答题情况列表
      this.startRecAnsList();
      // let newOptionList = JSON.parse(JSON.stringify(this.optionList));
      // newOptionList.forEach(item => {
      //   item.isChoose = 0;
      // });
      // let data = {
      //   quesTypeIndex: this.quesTypeIndex,
      //   rightOption: this.rightOption,
      //   optionList: newOptionList
      // };
      // var startAnswer_data = {
      //   data: data, // 题型，选项列表，正确选项
      //   description: '老师提交开始答题，发送题型，选项列表，正确选项', //自定义消息的说明字段
      //   action: 'startAnswer'
      // };
      // this.tic.sendGroupCustomMessage(JSON.stringify(startAnswer_data), function(res) {
      //   console.log('发送老师提交开始答题消息:', res);
      // });
    },
    // 开始轮询请求学生答题统计
    startRecAns() {
      this.stuAnsStat = setInterval(this.getStuAnswerStat, 1000);
    },
    // 结束轮询请求学生答题统计
    stopRecAns() {
      clearInterval(this.stuAnsStat);
    },
    // 开始轮询请求学生答题情况列表
    startRecAnsList() {
      this.stuAnsStatList = setInterval(this.getStuAnswerStatList, 1000);
    },
    // 结束轮询请求学生答题情况列表
    stopRecAnsList() {
      clearInterval(this.stuAnsStatList);
    },
    // 获取学生答题统计
    getStuAnswerStat() {
      this.get(`/live/lvb/question/studentAnswerStat/${this.questionId}`, {}, e => {
        if (e.code == 200) {
          this.accuracy = e.data.correctPercent + '%';
          this.totalCount = e.data.totalCount;
          this.answerCount = e.data.answerCount;
          this.questionAnswer = e.data.questionAnswer;
          // 1单选 2多选 3判断
          this.questionType = e.data.questionType;
          if (this.questionType == 3) {
            if (this.questionAnswer == 0) {
              this.questionAnswer = '错误';
            } else {
              this.questionAnswer = '正确';
            }
          }
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    getStuAnswerStatList() {
      this.get(`/live/lvb/question/studentAnswerList/${this.questionId}`, {}, e => {
        if (e.code == 200) {
          this.studentAnswersList = e.data;
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    // 学生提交答案 给老师发送通信
    studentSendAnswer() {
      if (!this.stuDisableSubmit) {
        this.stuDisableSubmit = true;
        let studentAnswer = '';
        if (this.quesTypeIndex == 0) {
          // 选择题
          this.rightOption.forEach(item => {
            studentAnswer = studentAnswer + item.value;
          });
        } else if (this.quesTypeIndex == 1) {
          // 判断题
          studentAnswer = String(this.curJuage);
        }
        // todo 学生提交答案
        this.post(
          '/live/lvb/question/answer',
          { questionId: this.questionId, studentAnswer: studentAnswer },
          e => {
            if (e.code == 200) {
              this.$.ui.toast('答案已提交');
            } else {
              this.$.ui.toast(e.msg);
            }
          }
        );

        // let obj = {
        //   id: this.userId,
        //   answer: this.rightOption
        // };
        // var stuAnswer_data = {
        //   data: obj, // 学生提交答案
        //   description: '学生提交答案消息', //自定义消息的说明字段
        //   action: 'studentSendAnswer'
        // };
        // this.tic.sendCustomMessage(this.teacher.userCode, JSON.stringify(stuAnswer_data), function(
        //   res
        // ) {
        //   console.log('===sendCustomGroupMsg:', res);
        // });
      } else {
        this.$.ui.toast('请不要重复提交答案');
      }
    },
    // 停止回答问题
    stopAnswerQues() {
      this.reset();
      // 停止轮询获取学生答题状态
      this.stopRecAns();
      // 停止轮询获取学生答题列表
      this.stopRecAnsList();
      this.curJuage = -1;
      (this.optionList = [
        { value: 'A', isChoose: 0 },
        { value: 'B', isChoose: 0 },
        { value: 'C', isChoose: 0 },
        { value: 'D', isChoose: 0 }
      ]),
        (this.responderType = 0),
        (this.responderIndex = 0),
        (this.countTime = '00:00'),
        (this.rightOption = []),
        (this.studentAnswersList = []),
        (this.accuracy = ''),
        (this.showResponder = false);
      this.curLive = -10;
      var stopAnswer_data = {
        description: '停止答题', //自定义消息的说明字段
        action: 'stopAnswer'
      };
      this.tic.sendGroupCustomMessage(JSON.stringify(stopAnswer_data), function(res) {
        console.log('发送停止答题消息:', res);
      });
    },
    // 改变答问详情类型
    changeResponder(index) {
      this.responderIndex = index;
    },
    // 改变问题答疑类型
    changeQuestionType(index) {
      this.questionTypeIndex = index;
    },
    // 问题答疑 上传图片
    sendQuestion() {
      this.showQuestion = false;
      this.post(
        '/live/lvb/pazzle/pazzle',
        { lessonId: this.lessonId, pazzleUrl: this.fileList[0].url },
        e => {
          if (e.code == 200) {
            this.$.ui.toast('发送成功');
          } else {
            this.$.ui.toast(e.msg);
          }
        }
      );
      // 向老师发送通信
      var stuAskQues_data = {
        description: '学生提交问题消息', //自定义消息的说明字段
        action: 'pazzle'
      };
      this.tic.sendCustomMessage(this.teacher.userCode, JSON.stringify(stuAskQues_data), function(
        res
      ) {
        console.log('===sendCustomGroupMsg:', res);
      });
    },
    successUpload(response, file, fileList) {
      let obj = {
        name: this.user.username,
        url: response.data
      };
      this.fileList.push(obj);
      console.log(this.fileList, 'fileList------------==========--------');
      console.log(response, 'response------------==========--------');
    },
    // 获取课程已关联的课件列表
    getCourseWares() {
      this.get('/live/lvb/lesson/getWareList', { lessonId: this.lessonId }, e => {
        if (e.code == 200) {
          this.courseWareList = e.data;
        } else {
          this.$.ui.toast(e.msg);
        }
      });
    },
    // 当选择问题答疑图片时
    onChooseQues(index, item) {
      this.curImg = index;
      this.curQuestion = item;
      console.log(this.curQuestion, 'this.curQuestion');
    },
    // 确认问题答疑图片
    checkQuestion() {
      this.addBoard();
      this.nextBoard();
      this.onAddImageElement(this.curQuestion.pazzleUrl);
      this.showQuestion = false;
      if (this.questionTypeIndex == 0) {
        this.post(`/live/lvb/pazzle/resolve/${this.curQuestion.id}`, {}, e => {
          if (e.code == 200) {
          } else {
            this.$.ui.toast(e.msg);
          }
        });
      }
    },
    // 添加课件
    addCourseWare(index, row) {
      this.openThumb = true;
      console.log(row);
      let config = {
        url: row.wareUrl,
        title: row.name,
        pages: row.pageNum,
        resolution: row.resolution
      };
      console.log('transcodeFile:', config);
      this.teduBoard.addTranscodeFile(config);
      this.showCourseware = false;
      this.curLive = -10;
    },
    formatSeconds(value) {
      var theTime = parseInt(value); // 秒
      var middle = 0; // 分
      var hour = 0; // 小时
      if (theTime > 60) {
        middle = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);
        if (middle > 60) {
          hour = parseInt(middle / 60);
          middle = parseInt(middle % 60);
        }
      }
      var result = '';
      if (parseInt(theTime) >= 10) {
        result = '0' + ':' + parseInt(theTime);
      } else {
        result = '0' + ':' + '0' + parseInt(theTime);
      }
      if (middle >= 0 && parseInt(theTime) >= 10) {
        result = parseInt(middle) + ':' + parseInt(theTime);
      } else {
        result = parseInt(middle) + ':' + '0' + parseInt(theTime);
      }
      return result;
    },
    onChangeChatState(value) {
      // 发送禁言通信
      var changeChat_data = {
        data: value,
        description: '全员禁言消息', //自定义消息的说明字段
        action: 'changeChat'
      };
      this.tic.sendGroupCustomMessage(JSON.stringify(changeChat_data), function(res) {
        console.log('发送禁言状态的消息:', res);
      });
    },
    // 全体静音
    muteAll() {
      // 发送全体静音通信
      var muteAll_data = {
        description: '全员静音消息', //自定义消息的说明字段
        action: 'muteAll'
      };
      this.tic.sendGroupCustomMessage(JSON.stringify(muteAll_data), function(res) {
        console.log('发送全体静音的消息:', res);
      });
      this.$.ui.toast('全体静音');
      this.micState = 0;
      this.teacher.speaking = false;
      if (this.localStream) {
        this.enableMic ? this.localStream.unmuteAudio() : this.localStream.muteAudio();
      }
    },
    // 切换视频头像大小
    toggleScreen(userId) {
      console.log('123');
      let flag;
      if (userId == this.teacher.userCode) {
        this.teacher.fullScreen = !this.teacher.fullScreen;
        flag = this.teacher.fullScreen;
      } else {
        for (let i = 0; i < this.studentInRoom.length; i++) {
          if (this.studentInRoom[i].userCode == userId) {
            this.studentInRoom[i].fullScreen = !this.studentInRoom[i].fullScreen;
            flag = this.studentInRoom[i].fullScreen;
            break;
          }
        }
      }
      if (flag) {
        // 发送放大头像通信
        var cameraFull_data = {
          data: userId,
          description: '放大头像', //自定义消息的说明字段
          action: 'cameraFull'
        };
        this.tic.sendGroupCustomMessage(JSON.stringify(cameraFull_data), function(res) {
          console.log('发送全体放大头像消息:', res);
        });
      } else {
        // 发送缩小头像通信
        var cameraSmall_data = {
          data: userId,
          description: '头像缩小', //自定义消息的说明字段
          action: 'cameraSmall'
        };
        this.tic.sendGroupCustomMessage(JSON.stringify(cameraSmall_data), function(res) {
          console.log('发送全体放大头像消息:', res);
        });
      }
    }
  },
  beforeDestroy() {
    this.quitClassroom();
  },
  components: {}
};
</script>

<style lang="less">
.isSpeaking {
  width: 199px;
  height: 113px;
  border: 2px solid #00cc66 !important;
}
.xbk-room {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background: #ffffff;
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // width: 1920px;
    background: #f5f5f7;
    .h-title {
      height: 30px;
      font-size: 14px;
      font-weight: 500;
      line-height: 30px;
      color: #45494d;
    }
    .h-right-btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: absolute;
      right: 70px;
      // right: 0px;
      span {
        margin-left: 5px;
        margin-right: 5px;
        cursor: pointer;
      }
      .t-time {
        height: 20px;
        background: #f0f0f2;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        color: #45494d;
        padding: 3px 6px;
      }
      .h-share {
        width: 20px;
        height: 20px;
        background: url(~@/assets/share-btn@2x.png) no-repeat center center;
        background-size: 20px 20px;
      }
      .h-info {
        width: 20px;
        height: 20px;
        background: url(~@/assets/info-btn@2x.png) no-repeat center center;
        background-size: 20px 20px;
      }
      .h-signal {
        width: 20px;
        height: 20px;
        background: url(~@/assets/single@2x.png) no-repeat center center;
        background-size: 20px 20px;
      }
      .h-max {
        width: 20px;
        height: 20px;
        background: url(~@/assets/full-btn@2x.png) no-repeat center center;
        background-size: 20px 20px;
      }
      .h-mini {
        width: 20px;
        height: 20px;
        background: url(~@/assets/minimize@2x.png) no-repeat center center;
        background-size: 20px 20px;
      }
    }
  }
  .courseware {
    transition: all 0.3s;
    padding-top: 10px;
    margin-top: 30px;
    margin-bottom: 40px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 15px;
    width: 155px;
    background: #ffffff;
    position: relative;
    .c-item {
      width: 155px;
      margin-bottom: 13px;
      .c-wrap {
        display: flex;
        flex-direction: row;
        span {
          text-align: center;
          width: 23px;
          height: 61px;
          font-size: 11px;
          font-family: SF Pro Text;
          font-weight: 500;
          line-height: 61px;
          color: #45484d;
        }
        img {
          width: 107px;
          height: 61px;
          border: 2px solid #c8cacc;
          border-radius: 5px;
        }
        img.c-current {
          width: 107px;
          height: 61px;
          border-radius: 5px;
          border: 2px solid #27c840;
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.16);
        }
      }
    }
    .button-wrap {
      position: absolute;
      left: 155px;
      top: 48%;
      cursor: pointer;
      z-index: 9999;
      width: 15px;
      height: 38px;
      background-color: #ffffff;
      border-radius: 0px 8px 8px 0px;
      .open {
        display: block;
        width: 15px;
        height: 38px;
        background: url(~@/assets/close.png) no-repeat center center;
        background-size: 11px 11px;
      }
      .close {
        display: block;
        width: 15px;
        height: 38px;
        background: url(~@/assets/open.png) no-repeat center center;

        background-size: 11px 11px;
      }
    }
  }
  .board-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .board-tab {
      margin-top: 30px;
      background: #bbbbbb;
      height: 23px;
      .el-tabs__header {
        height: 23px;
      }
      .el-tabs__nav-wrap {
        height: 23px;
      }
      .el-tabs__nav-scroll {
        height: 23px;
      }
      .el-tabs__nav {
        height: 23px;
      }
      .el-tabs__item {
        border-radius: 4px;
        height: 23px;
        font-size: 11px;
        font-family: SF Pro Text;
        font-weight: 500;
        line-height: 23px;
        color: #2e3033;
      }
      .is-active {
        border-radius: 4px;
        background: #ffffff;
      }
      .el-icon-close {
        top: -3px;
      }
      .b-item {
        height: 23px;
      }
    }
    #paint_box {
      position: relative;
      width: calc(100vw - 376px);
      height: calc(100vh - 93px);
      overflow: hidden;
      box-sizing: content-box;
      transition: all 0.3s;
      background: #1a1a1a;
    }
  }

  .board-button {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    width: 100%;
    height: 40px;
    .turn-page {
      display: flex;
      flex-direction: row;
      align-items: center;
      .t-left {
        width: 40px;
        height: 40px;
        background: url(~@/assets/left@2x.png) no-repeat center center;
        background-size: 18px 18px;
      }
      .t-text {
        font-size: 12px;
        font-weight: 500;
        line-height: 40px;
        color: #17181a;
        height: 40px;
        text-align: center;
      }
      .t-right {
        width: 40px;
        height: 40px;
        background: url(~@/assets/right@2x.png) no-repeat center center;
        background-size: 18px 18px;
      }
      .t-add {
        width: 40px;
        height: 40px;
        background: url(~@/assets/add-btn.png) no-repeat center center;
        background-size: 18px 18px;
      }
    }
    .live-btn {
      display: flex;
      flex-direction: row;
      height: 40px;
      .camera {
        cursor: pointer;
        width: 80px;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/camera.png) no-repeat center center;
          background-size: 19px 19px;
        }
      }
      .camera-open {
        cursor: pointer;
        width: 80px;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/camera-open.png) no-repeat center center;
          background-size: 19px 19px;
        }
      }
      .camera-close {
        cursor: pointer;
        width: 80px;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/camera-close.png) no-repeat center center;
          background-size: 19px 19px;
        }
      }
      span {
        cursor: pointer;
        height: 11px;
        font-size: 8px;
        font-weight: 500;
        line-height: 11px;
        color: #393c40;
      }
      .microphone {
        cursor: pointer;
        width: 80px;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon-mute {
          width: 19px;
          height: 19px;
          background: url(~@/assets/mute@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/microphone@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
      }
      .micro-btn-active {
        cursor: pointer;
        width: 15px;
        height: 40px;
        line-height: 40px;
        background-color: #edeff2;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          width: 15px;
          height: 40px;
          background: url(~@/assets/triangle-active.png) no-repeat center center;
          background-size: 8px 5px;
        }
      }
      .micro-btn {
        cursor: pointer;
        width: 15px;
        height: 40px;
        line-height: 40px;
        background-color: #ffffff;
        border-radius: 3px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          width: 15px;
          height: 40px;
          background: url(~@/assets/triangle@2x.png) no-repeat center center;
          background-size: 8px 5px;
        }
      }
      .question {
        position: relative;
        cursor: pointer;
        width: 54px;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/question@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
        .dot {
          width: 5px;
          line-height: 5px;
          height: 5px;
          position: absolute;
          padding: 2px;
          top: 2px;
          left: 30px;
          text-align: center;
          background: #fe3b30;
          border-radius: 50%;
          font-size: 9px;
          font-family: SF Pro Text;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .question-active {
        position: relative;
        cursor: pointer;
        width: 54px;
        height: 40px;
        display: flex;
        background: #edf0f2;
        border-radius: 6px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span {
          color: #0069f2;
        }
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/question-active@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
        .dot {
          width: 5px;
          line-height: 5px;
          height: 5px;
          position: absolute;
          padding: 2px;
          top: 2px;
          left: 30px;
          text-align: center;
          background: #fe3b30;
          border-radius: 50%;
          font-size: 9px;
          font-family: SF Pro Text;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .answer {
        cursor: pointer;
        width: 54px;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/answer@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
      }
      .answer-active {
        cursor: pointer;
        width: 54px;
        height: 40px;
        display: flex;
        background: #edf0f2;
        opacity: 1;
        border-radius: 6px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        span {
          color: #0069f2;
        }
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/answer-active@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
      }
      .ask {
        position: relative;
        cursor: pointer;
        width: 54px;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/hand@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
        .dot {
          width: 10px;
          line-height: 10px;
          height: 10px;
          position: absolute;
          padding: 2px;
          top: 2px;
          left: 30px;
          text-align: center;
          background: #fe3b30;
          border-radius: 50%;
          font-size: 9px;
          font-family: SF Pro Text;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .ask-active {
        position: relative;
        cursor: pointer;
        width: 54px;
        height: 40px;
        background: #edf0f2;
        opacity: 1;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/hand-active@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
        span {
          color: #0069f2;
        }
        .dot {
          width: 10px;
          line-height: 10px;
          height: 10px;
          position: absolute;
          padding: 2px;
          top: 2px;
          left: 30px;
          text-align: center;
          background: #fe3b30;
          border-radius: 50%;
          font-size: 9px;
          font-family: SF Pro Text;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .chat {
        position: relative;
        cursor: pointer;
        width: 54px;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/message@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
        .dot {
          width: 5px;
          line-height: 5px;
          height: 5px;
          position: absolute;
          padding: 2px;
          top: 2px;
          left: 30px;
          text-align: center;
          background: #fe3b30;
          border-radius: 50%;
          font-size: 9px;
          font-family: SF Pro Text;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .chat-active {
        position: relative;
        cursor: pointer;
        width: 54px;
        height: 40px;
        background: #edf0f2;
        opacity: 1;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .dot {
          width: 5px;
          line-height: 5px;
          height: 5px;
          position: absolute;
          padding: 2px;
          top: 2px;
          left: 30px;
          text-align: center;
          background: #fe3b30;
          border-radius: 50%;
          font-size: 9px;
          font-family: SF Pro Text;
          font-weight: 500;
          color: #ffffff;
        }
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/message-active@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
        span {
          color: #0069f2;
        }
      }
      .record {
        cursor: pointer;
        width: 54px;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/circle.png) no-repeat center center;
          background-size: 19px 19px;
        }
      }
      .record-active {
        cursor: pointer;
        width: 54px;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/circle-hollow-active.png) no-repeat center center;
          background-size: 19px 19px;
        }
        span {
          color: #0069f2;
        }
      }
      .ware {
        cursor: pointer;
        width: 54px;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/file@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
      }
      .ware-active {
        cursor: pointer;
        width: 54px;
        height: 40px;
        display: flex;
        background: #edf0f2;
        opacity: 1;
        border-radius: 6px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/file-active.png) no-repeat center center;
          background-size: 19px 19px;
        }
        span {
          color: #0069f2;
        }
      }
      .setting {
        cursor: pointer;
        width: 54px;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/file@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
      }
    }
    .line {
      width: 1px;
      height: 33px;
      background: #d8d8d8;
      opacity: 1;
      margin-left: 7px;
      margin-right: 7px;
    }
    .board-btn {
      display: flex;
      flex-direction: row;
      span {
        cursor: pointer;
        height: 11px;
        font-size: 8px;
        font-weight: 500;
        line-height: 11px;
        color: #393c40;
      }
      .choice {
        cursor: pointer;
        width: 41px;
        height: 40px;
        margin-left: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/mouse@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
        .icon-active {
          width: 19px;
          height: 19px;
          background: url(~@/assets/mouse-active@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
      }
      .choice-active {
        cursor: pointer;
        width: 41px;
        height: 40px;
        margin-left: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #edf0f2;
        border-radius: 6px;
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/mouse-active@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
        span {
          cursor: pointer;
          height: 11px;
          font-size: 8px;
          font-weight: 500;
          line-height: 11px;
          color: #0069f2;
        }
      }
      .laser-pen {
        cursor: pointer;
        width: 41px;
        height: 40px;
        margin-left: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/laser-pen@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
      }
      .laser-pen-active {
        cursor: pointer;
        width: 41px;
        height: 40px;
        margin-left: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #edf0f2;
        border-radius: 6px;
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/laserpen-active@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
        span {
          cursor: pointer;
          height: 11px;
          font-size: 8px;
          font-weight: 500;
          line-height: 11px;
          color: #0069f2;
        }
      }
      .pen {
        cursor: pointer;
        width: 41px;
        height: 40px;
        margin-left: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/pen@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
      }
      .pen-active {
        cursor: pointer;
        width: 41px;
        height: 40px;
        margin-left: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #edf0f2;
        border-radius: 6px;
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/pen-active@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
        span {
          cursor: pointer;
          height: 11px;
          font-size: 8px;
          font-weight: 500;
          line-height: 11px;
          color: #0069f2;
        }
      }
      .text {
        cursor: pointer;
        width: 41px;
        height: 40px;
        margin-left: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/text@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
        .icon-active {
          width: 19px;
          height: 19px;
          background: url(~@/assets/text-active@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
      }
      .text-active {
        cursor: pointer;
        width: 41px;
        height: 40px;
        margin-left: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #edf0f2;
        border-radius: 6px;
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/text-active@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
        span {
          cursor: pointer;
          height: 11px;
          font-size: 8px;
          font-weight: 500;
          line-height: 11px;
          color: #0069f2;
        }
      }
      .eraser {
        cursor: pointer;
        width: 41px;
        height: 40px;
        margin-left: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/eraser@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
      }
      .eraser-active {
        cursor: pointer;
        width: 41px;
        height: 40px;
        margin-left: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #edf0f2;
        border-radius: 6px;
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/eraser-active@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
        span {
          cursor: pointer;
          height: 11px;
          font-size: 8px;
          font-weight: 500;
          line-height: 11px;
          color: #0069f2;
        }
      }
      .graph {
        cursor: pointer;
        width: 41px;
        height: 40px;
        margin-left: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/graph@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
      }
      .graph-active {
        cursor: pointer;
        width: 41px;
        height: 40px;
        margin-left: 8px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #edf0f2;
        border-radius: 6px;
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/graph-active@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
        span {
          cursor: pointer;
          height: 11px;
          font-size: 8px;
          font-weight: 500;
          line-height: 11px;
          color: #0069f2;
        }
      }
    }
    .operation-btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      span {
        cursor: pointer;
        height: 11px;
        font-size: 8px;
        font-weight: 500;
        line-height: 11px;
        color: #393c40;
      }
      .revoke {
        cursor: pointer;
        width: 54px;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/revoke@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
        .icon-active {
          width: 19px;
          height: 19px;
          background: url(~@/assets/revoke-active@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
      }
      .clear {
        cursor: pointer;
        width: 54px;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/delete@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
        .icon-active {
          width: 19px;
          height: 19px;
          background: url(~@/assets/delete-active@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
      }
      .capture {
        cursor: pointer;
        width: 54px;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon {
          width: 19px;
          height: 19px;
          background: url(~@/assets/delete@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
        .icon-active {
          width: 19px;
          height: 19px;
          background: url(~@/assets/delete-active@2x.png) no-repeat center center;
          background-size: 19px 19px;
        }
      }
    }
    .course-btn {
      cursor: pointer;
      margin-left: 80px;
      text-align: center;
      width: 57px;
      height: 27px;
      background: #ffffff;
      border: 1px solid #fe3b30;
      border-radius: 6px;
      font-size: 11px;
      font-weight: bold;
      line-height: 27px;
      color: #fe3b30;
      .el-button {
        width: 57px;
        height: 27px;
        padding: unset;
        span {
          border: unset;
        }
      }
    }
  }
  .video-box {
    margin-bottom: 40px;
    margin-top: 30px;
    // position: relative;
    width: 206px;
    background: #050505;
    padding: 2px 4px;
    box-sizing: border-box;
    // #video_teachers {
    //   box-sizing: border-box;
    //   width: 190px;
    //   height: 105px;
    //   border-radius: 8px;
    //   div {
    //     box-sizing: border-box;
    //     width: 190px;
    //     height: 105px;
    //     border-radius: 8px;
    //   }
    //   video {
    //     box-sizing: border-box;
    //     width: 190px;
    //     height: 105px;
    //     border-radius: 8px;
    //     z-index: 999;
    //     margin-bottom: 6px;
    //   }
    // }
    .video_teachers {
      box-sizing: border-box;
      width: 190px;
      height: 105px;
      border-radius: 8px;
      div {
        box-sizing: border-box;
        width: 190px;
        height: 105px;
        border-radius: 8px;
      }
      video {
        box-sizing: border-box;
        width: 190px;
        height: 105px;
        border-radius: 8px;
        z-index: 9;
        margin-bottom: 6px;
      }
    }
    .video_teachers_full {
      box-sizing: border-box;
      width: 596px;
      height: 335px;
      border-radius: 8px;
      div {
        box-sizing: border-box;
        width: 596px;
        height: 335px;
        border-radius: 8px;
      }
      video {
        box-sizing: border-box;
        width: 596px;
        height: 335px;
        border-radius: 8px;
        z-index: 9;
        margin-bottom: 6px;
      }
    }
    // #local_video {
    //   display: flex;
    //   align-items: center;
    //   width: 191px;
    //   height: 105px;
    //   display: flex;
    //   border-radius: 8px;
    // }
    // #video_wrap {
    //   width: 199px;
    //   height: 113px;
    //   display: flex;
    //   border-radius: 8px;
    // }
    // #video_wrap > div {
    //   width: 199px;
    //   height: 113px;
    //   border-radius: 8px;
    // }
    .teacher {
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 199px;
      height: 113px;
      background: #2e3033;
      border: 2px solid #5c6166;
      opacity: 1;
      border-radius: 8px;

      .avatar {
        margin-top: -15px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: no-repeat center center;
        background-size: cover;
      }
      .info {
        width: 195px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 99;
        .left {
          height: 17px;
          background-color: #202224;
          border-radius: 0px 6px 0px 6px;
          display: flex;
          flex-direction: row;
          align-items: center;
          background-color: rgba(0, 0, 0, 0.5);
          .icon {
            width: 17px;
            height: 17px;
            background: url(~@/assets/teacher@2x.png) no-repeat center center;
            background-size: 17px 17px;
            border-radius: 0px 6px 0px 6px;
          }
          .name {
            height: 17px;
            font-size: 9px;
            font-family: SF Pro Text;
            font-weight: bold;
            line-height: 17px;
            color: #ffffff;
            padding: 0 8px;
          }
        }
        .right {
          height: 17px;
          background-color: #202224;
          border-radius: 6px 0px 6px 0px;
          display: flex;
          flex-direction: row;
          align-items: center;
          background-color: rgba(0, 0, 0, 0.5);
          .microphone {
            width: 26px;
            height: 16px;
            background: url(~@/assets/microphone-0.png) no-repeat center center;
            background-size: 13px 13px;
            border-radius: 0px 6px 0px 6px;
          }
          .microphone-close {
            width: 26px;
            height: 16px;
            background: url(~@/assets/microphone-close-0.png) no-repeat center center;
            background-size: 13px 13px;
            border-radius: 0px 6px 0px 6px;
          }
          .camera {
            width: 26px;
            height: 16px;
            background: url(~@/assets/video-on-0.png) no-repeat center center;
            background-size: 13px 13px;
            border-radius: 0px 6px 0px 6px;
          }
          .camera-close {
            width: 26px;
            height: 16px;
            background: url(~@/assets/camera-close-0.png) no-repeat center center;
            background-size: 13px 13px;
            border-radius: 0px 6px 0px 6px;
          }
          .full-screen {
            width: 26px;
            height: 16px;
            background: url(~@/assets/full-screen.png) no-repeat center center;
            background-size: 13px 13px;
            border-radius: 0px 6px 0px 6px;
          }
          .mini-screen {
            width: 26px;
            height: 16px;
            background: url(~@/assets/mini-screen.png) no-repeat center center;
            background-size: 13px 13px;
            border-radius: 0px 6px 0px 6px;
          }
        }
      }
    }
    .teacher_full {
      position: absolute;
      top: 30%;
      left: 30%;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 600px;
      height: 339px;
      background: #2e3033;
      border: 2px solid #5c6166;
      opacity: 1;
      border-radius: 8px;
      .avatar {
        margin-top: -15px;
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background: no-repeat center center;
        background-size: cover;
      }
      .info {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 99;
        .left {
          height: 17px;
          background-color: #202224;
          border-radius: 0px 6px 0px 6px;
          display: flex;
          flex-direction: row;
          align-items: center;
          background-color: rgba(0, 0, 0, 0.5);
          .icon {
            width: 17px;
            height: 17px;
            background: url(~@/assets/teacher@2x.png) no-repeat center center;
            background-size: 17px 17px;
            border-radius: 0px 6px 0px 6px;
          }
          .name {
            height: 17px;
            font-size: 9px;
            font-family: SF Pro Text;
            font-weight: bold;
            line-height: 17px;
            color: #ffffff;
            padding: 0 8px;
          }
        }
        .right {
          height: 17px;
          background-color: #202224;
          border-radius: 6px 0px 6px 0px;
          display: flex;
          flex-direction: row;
          align-items: center;
          background-color: rgba(0, 0, 0, 0.5);
          .microphone {
            width: 26px;
            height: 16px;
            background: url(~@/assets/microphone-0.png) no-repeat center center;
            background-size: 13px 13px;
            border-radius: 0px 6px 0px 6px;
          }
          .microphone-close {
            width: 26px;
            height: 16px;
            background: url(~@/assets/microphone-close-0.png) no-repeat center center;
            background-size: 13px 13px;
            border-radius: 0px 6px 0px 6px;
          }
          .camera {
            width: 26px;
            height: 16px;
            background: url(~@/assets/video-on-0.png) no-repeat center center;
            background-size: 13px 13px;
            border-radius: 0px 6px 0px 6px;
          }
          .camera-close {
            width: 26px;
            height: 16px;
            background: url(~@/assets/camera-close-0.png) no-repeat center center;
            background-size: 13px 13px;
            border-radius: 0px 6px 0px 6px;
          }
          .full-screen {
            width: 26px;
            height: 16px;
            background: url(~@/assets/full-screen.png) no-repeat center center;
            background-size: 13px 13px;
            border-radius: 0px 6px 0px 6px;
          }
          .mini-screen {
            width: 26px;
            height: 16px;
            background: url(~@/assets/mini-screen.png) no-repeat center center;
            background-size: 13px 13px;
            border-radius: 0px 6px 0px 6px;
          }
        }
      }
    }
    .v-wrap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 30px;
      .t-title {
        padding-left: 10px;
        height: 30px;
        line-height: 30px;
        font-size: 9px;
        font-weight: bold;
        color: #ffffff;
      }
      .btns {
        width: 141px;
        height: 17px;
        background: #373737;
        border-radius: 74px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .phone {
          width: 45px;
          height: 14px;
          border-radius: 74px;
          background: url(~@/assets/phone-in-0@2x.png) no-repeat center center;
          background-size: 11px 11px;
        }
        .list {
          width: 45px;
          height: 14px;
          border-radius: 74px;
          background: url(~@/assets/list-0.png) no-repeat center center;
          background-size: 11px 11px;
        }
        .toggle {
          width: 45px;
          height: 14px;
          border-radius: 74px;
          background: url(~@/assets/toggle.png) no-repeat center center;
          background-size: 11px 11px;
        }
      }
    }
    .tab {
      width: 198px;
      height: 23px;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 6px;
      .t-item {
        box-sizing: border-box;
        cursor: pointer;
        margin: 2px;
        text-align: center;
        width: 97px;
        height: 20px;
        background-color: #373737;
        border-radius: 3px;
        font-size: 9px;
        font-weight: 500;
        line-height: 20px;
        color: #cccccc;
      }
      .t-current {
        cursor: pointer;
        box-sizing: border-box;
        text-align: center;
        width: 97px;
        height: 20px;
        background-color: #ebebeb;
        border-radius: 3px;
        font-size: 9px;
        font-weight: 500;
        line-height: 20px;
        color: #45484d;
      }
    }
    .list-title {
      padding: 6px 8px;
      width: 199px;
      span {
        width: 199px;
        text-align: center;
        height: 12px;
        font-size: 9px;
        font-family: SF Pro Text;
        font-weight: bold;
        line-height: 12px;
        color: #ffffff;
        opacity: 1;
      }
    }
    .list-wrap {
      // position: fixed;
      // top: 153px;
      // bottom: 40px;
      overflow-y: auto;
      .student-list {
        display: flex;
        flex-direction: column;
        .s-item-active {
          position: relative;
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          position: relative;
          width: 199px;
          height: 113px;
          background: #2e3033;
          border: 2px solid #5c6166;
          opacity: 1;
          border-radius: 8px;
          margin-top: 6px;
          #video_student {
            box-sizing: border-box;
            width: 190px;
            height: 105px;
            border-radius: 8px;
            div {
              box-sizing: border-box;
              width: 190px;
              height: 105px;
              border-radius: 8px;
            }
            video {
              box-sizing: border-box;
              width: 190px;
              height: 105px;
              border-radius: 8px;
              z-index: 999;
            }
          }
          .avatar {
            position: absolute;
            top: center;
            right: center;
            margin-top: -15px;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: no-repeat center center;
            background-size: cover;
          }
          .info {
            width: 195px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 9999;
            .left {
              height: 17px;
              background-color: #202224;
              border-radius: 0px 6px 0px 6px;
              display: flex;
              flex-direction: row;
              align-items: center;
              background-color: rgba(0, 0, 0, 0.5);

              .icon-mic {
                width: 13px;
                height: 13px;
                background: url(~@/assets/microphone-0.png) no-repeat center center;
                background-size: 13px 13px;
                border-radius: 0px 6px 0px 6px;
              }
              .icon-pen {
                width: 17px;
                height: 17px;
                background: url(~@/assets/pen-0.png) no-repeat center center;
                background-size: 13px 13px;
                border-radius: 0px 6px 0px 6px;
              }
              .icon-pen-active {
                width: 17px;
                height: 17px;
                background: url(~@/assets/pen-active.png) no-repeat center center;
                background-size: 13px 13px;
                border-radius: 0px 6px 0px 6px;
              }
              .icon-video {
                width: 13px;
                height: 13px;
                background: url(~@/assets/video-on-0.png) no-repeat center center;
                background-size: 13px 13px;
                border-radius: 0px 6px 0px 6px;
              }
              .name {
                height: 17px;
                font-size: 9px;
                font-family: SF Pro Text;
                font-weight: bold;
                line-height: 17px;
                color: #ffffff;
                padding: 0 8px;
              }
            }
            .right {
              height: 17px;
              background-color: #202224;
              border-radius: 6px 0px 6px 0px;
              display: flex;
              flex-direction: row;
              background-color: rgba(0, 0, 0, 0.5);
              .phone {
                width: 26px;
                height: 16px;
                background: url(~@/assets/phone-in-0@2x.png) no-repeat center center;
                background-size: 11px 11px;
                border-radius: 6px 0px 0px 0px;
              }
              .phone-close {
                width: 26px;
                height: 16px;
                background: url(~@/assets/phone-close.png) no-repeat center center;
                background-size: 11px 11px;
              }
              .leave {
                width: 26px;
                height: 16px;
                background: url(~@/assets/leave-0@2x.png) no-repeat center center;
                background-size: 11px 11px;
                border-radius: 0px 0px 6px 0px;
              }
              .full-screen {
                width: 26px;
                height: 16px;
                background: url(~@/assets/full-screen.png) no-repeat center center;
                background-size: 13px 13px;
                border-radius: 0px 6px 0px 6px;
              }
              .mini-screen {
                width: 26px;
                height: 16px;
                background: url(~@/assets/mini-screen.png) no-repeat center center;
                background-size: 13px 13px;
                border-radius: 0px 6px 0px 6px;
              }
            }
          }
        }
        .s-item-active-full {
          position: absolute;
          top: 30%;
          left: 30%;
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 600px;
          height: 339px;
          background: #2e3033;
          border: 2px solid #5c6166;
          opacity: 1;
          border-radius: 8px;
          margin-top: 6px;
          #video_student {
            box-sizing: border-box;
            width: 596px;
            height: 335px;
            border-radius: 8px;
            div {
              box-sizing: border-box;
              width: 596px;
              height: 335px;
              border-radius: 8px;
            }
            video {
              box-sizing: border-box;
              width: 596px;
              height: 335px;
              border-radius: 8px;
              z-index: 999;
            }
          }
          .avatar {
            position: absolute;
            top: center;
            right: center;
            margin-top: -15px;
            width: 90px;
            height: 90px;
            border-radius: 50%;
            background: no-repeat center center;
            background-size: cover;
          }
          .info {
            width: 598px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 9999;
            .left {
              height: 17px;
              background-color: #202224;
              border-radius: 0px 6px 0px 6px;
              display: flex;
              flex-direction: row;
              align-items: center;
              background-color: rgba(0, 0, 0, 0.5);
              .icon-mic {
                width: 13px;
                height: 13px;
                background: url(~@/assets/microphone-0.png) no-repeat center center;
                background-size: 13px 13px;
                border-radius: 0px 6px 0px 6px;
              }
              .icon-pen {
                width: 17px;
                height: 17px;
                background: url(~@/assets/pen-0.png) no-repeat center center;
                background-size: 13px 13px;
                border-radius: 0px 6px 0px 6px;
              }
              .icon-pen-active {
                width: 17px;
                height: 17px;
                background: url(~@/assets/pen-active.png) no-repeat center center;
                background-size: 13px 13px;
                border-radius: 0px 6px 0px 6px;
              }
              .icon-video {
                width: 13px;
                height: 13px;
                background: url(~@/assets/video-on-0.png) no-repeat center center;
                background-size: 13px 13px;
                border-radius: 0px 6px 0px 6px;
              }
              .name {
                height: 17px;
                font-size: 9px;
                font-family: SF Pro Text;
                font-weight: bold;
                line-height: 17px;
                color: #ffffff;
                padding: 0 8px;
              }
            }
            .right {
              height: 17px;
              background-color: #202224;
              border-radius: 6px 0px 6px 0px;
              display: flex;
              flex-direction: row;
              background-color: rgba(0, 0, 0, 0.5);
              .phone {
                width: 26px;
                height: 16px;
                background: url(~@/assets/phone-in-0@2x.png) no-repeat center center;
                background-size: 11px 11px;
                border-radius: 6px 0px 0px 0px;
              }
              .phone-close {
                width: 26px;
                height: 16px;
                background: url(~@/assets/phone-close.png) no-repeat center center;
                background-size: 11px 11px;
              }
              .leave {
                width: 26px;
                height: 16px;
                background: url(~@/assets/leave-0@2x.png) no-repeat center center;
                background-size: 11px 11px;
                border-radius: 0px 0px 6px 0px;
              }
              .full-screen {
                width: 26px;
                height: 16px;
                background: url(~@/assets/full-screen.png) no-repeat center center;
                background-size: 13px 13px;
                border-radius: 0px 6px 0px 6px;
              }
              .mini-screen {
                width: 26px;
                height: 16px;
                background: url(~@/assets/mini-screen.png) no-repeat center center;
                background-size: 13px 13px;
                border-radius: 0px 6px 0px 6px;
              }
            }
          }
        }
        .s-item-in {
          box-sizing: border-box;
          width: 195px;
          height: 45px;
          background: #2e3033;
          border: 2px solid #5c6166;
          opacity: 1;
          border-radius: 8px;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 6px;
          padding: 8px;
          .avatar {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: no-repeat center center;
            background-size: cover;
          }
          .info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 165px;
            .name {
              margin-left: 8px;
              height: 12px;
              font-size: 9px;
              font-family: SF Pro Text;
              font-weight: bold;
              line-height: 12px;
              color: #ffffff;
              text-align: center;
              padding: 2px 8px;
              background-color: rgba(0, 0, 0, 0.5);
              border-radius: 6px;
            }
            .right {
              height: 17px;
              background-color: #202224;
              border-radius: 6px 0px 6px 0px;
              display: flex;
              flex-direction: row;
              background-color: rgba(0, 0, 0, 0.5);
              .phone {
                width: 26px;
                height: 16px;
                background: url(~@/assets/phone-in-0@2x.png) no-repeat center center;
                background-size: 11px 11px;
                border-radius: 6px 0px 0px 0px;
              }
              .phone-close {
                width: 26px;
                height: 16px;
                background: url(~@/assets/phone-close.png) no-repeat center center;
                background-size: 11px 11px;
              }
              .leave {
                width: 26px;
                height: 16px;
                background: url(~@/assets/leave-0@2x.png) no-repeat center center;
                background-size: 11px 11px;
                border-radius: 0px 0px 6px 0px;
              }
            }
          }
        }
        .s-item-out {
          box-sizing: border-box;
          width: 199px;
          height: 45px;
          background: #2e3033;
          border: 2px solid #5c6166;
          opacity: 1;
          border-radius: 8px;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 6px;
          padding: 8px;
          filter: grayscale(1);
          .avatar {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: no-repeat center center;
            background-size: cover;
          }
          .info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 165px;
            .name {
              margin-left: 8px;
              height: 12px;
              font-size: 9px;
              font-family: SF Pro Text;
              font-weight: bold;
              line-height: 12px;
              color: #ffffff;
              text-align: center;
              padding: 2px 8px;
              border-radius: 6px;
              background-color: rgba(0, 0, 0, 0.3);
            }
          }
        }
      }
    }
  }
}
.el-dialog {
  border-radius: 10px;
}
.dialog-container {
  /deep/ .el-dialog {
    border-radius: 10px !important;
  }
  .el-dialog__headerbtn {
    top: 11px;
  }
  .el-dialog__header {
    line-height: 23px;
    height: 23px;
    text-align: center;
    padding: 5px;
    background: #f5f5f7;
    border-radius: 10px 10px 0 0;
    .el-dialog__title {
      height: 23px;
      font-size: 11px;
      font-family: SF Pro Text;
      font-weight: 500;
      line-height: 23px;
      color: #45494d;
    }
  }
  .el-dialog__body {
    padding: unset;
    background: #ffffff;
    border-radius: 10px;
  }
  .el-dialog__body {
    border-radius: 10px;
  }
  .el-table {
    border-radius: 0 0 10px 10px;
    padding-left: 20px;
  }
}
.courseware-wrap {
  height: 480px;
  overflow-y: auto;
}
.container-wrap {
  height: unset !important;
  padding-bottom: 0px !important;
  .img-wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .imgList {
      height: 300px;
      overflow-y: auto;
      padding: 12px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      .img-item {
        position: relative;
        margin-right: 10px;
        width: 125px;
        height: 125px;
        box-sizing: border-box;
        border: 2px solid #ffffff;
        margin-bottom: 5px;
        .i-tag {
          display: block;
          position: absolute;
          right: 0;
          bottom: 0;
          padding: 2px 4px;
          background: #000000;
          opacity: 0.5;
          border-radius: 4px 0px 4px 0px;
          height: 13px;
          font-size: 9px;
          font-family: SF Pro Text;
          font-weight: bold;
          line-height: 13px;
          color: #ffffff;
        }
        .questionImg {
          box-sizing: border-box;
          width: 121px;
          height: 121px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          opacity: 1;
          border-radius: 4px;
        }
      }
      .img-item-active {
        margin-right: 10px;
        box-sizing: border-box;
        position: relative;
        width: 125px;
        height: 125px;
        border: 2px solid #0061f2;
        margin-bottom: 5px;
        .i-tag {
          display: block;
          position: absolute;
          right: 0;
          bottom: 0;
          padding: 2px 4px;
          background: #000000;
          opacity: 0.5;
          border-radius: 4px 0px 4px 0px;
          height: 13px;
          font-size: 9px;
          font-family: SF Pro Text;
          font-weight: bold;
          line-height: 13px;
          color: #ffffff;
        }
        .questionImg {
          box-sizing: border-box;
          width: 121px;
          height: 121px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          opacity: 1;
          border-radius: 4px;
        }
      }
    }
  }
  .question-wrap {
    padding: 12px;
    .upload-wrap {
      height: 336px;
      overflow-y: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .c-title {
      padding-bottom: 20px;
    }
    .wrap-row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 7px;
      border-top: 1px solid #dcdcde;
      margin-top: 20px;
      .subBtn {
        width: 66px;
        height: 23px;
        background: #0069f2;
        border-radius: 4px;
        padding: unset;
        font-size: 9px;
        font-family: SF Pro Text;
        font-weight: bold;
        line-height: 23px;
        color: #ffffff;
        border: none;
      }
      .cancleBtn {
        width: 66px;
        height: 23px;
        background: #ffffff;
        border-radius: 4px;
        padding: unset;
        font-size: 9px;
        font-family: SF Pro Text;
        font-weight: bold;
        line-height: 23px;
        color: #7e848c;
        border: 1px solid rgba(0, 0, 0, 0.1);
      }
    }
  }
  .tab-bar {
    height: 36px;
    background-color: #edeff2;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .tab-list {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 182px;
      height: 23px;
      background: #dcdee1;
      border-radius: 4px;
    }
    .t-current {
      margin: 1.5px;
      text-align: center;
      width: 89px;
      height: 20px;
      background: #ffffff;
      font-weight: bold;
      border-radius: 3px;
      font-size: 9px;
      line-height: 20px;
      color: #2c2d2d;
    }
    .t-item {
      margin: 1.5px;
      text-align: center;
      width: 89px;
      height: 20px;
      background: #dcdee1;
      border-radius: 3px;
      font-size: 9px;
      line-height: 20px;
      color: #2c2d2d;
    }
  }
  .content {
    padding: 15px;
    .questionImg {
      width: 121px;
      height: 121px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }
    .questionImg-active {
      width: 121px;
      height: 121px;
      border: 2px solid #0061f2;
      border-radius: 4px;
    }
    .title {
      width: 180px;
      height: 15px;
      font-size: 11px;
      font-weight: 500;
      line-height: 15px;
      color: #17181a;
    }
    .options {
      margin: 8px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      span {
        cursor: pointer;
        text-align: center;
        margin-right: 10px;
        width: 23px;
        height: 23px;
        background: #f2f4f7;
        border-radius: 4px;
        font-size: 11px;
        font-weight: 500;
        line-height: 23px;
        color: #45484d;
        margin-bottom: 8px;
      }
      .right {
        cursor: pointer;
        text-align: center;
        margin-right: 10px;
        width: 23px;
        height: 23px;
        border-radius: 4px;
        font-size: 11px;
        font-weight: 500;
        line-height: 23px;
        color: #45484d;
        margin-bottom: 8px;
        background: #45484d url(~@/assets/right-0@2x.png) no-repeat center center;
        background-size: 18px 18px;
      }
      .right-active {
        cursor: pointer;
        text-align: center;
        margin-right: 10px;
        width: 23px;
        height: 23px;
        border-radius: 4px;
        font-size: 11px;
        font-weight: 500;
        line-height: 23px;
        color: #45484d;
        margin-bottom: 8px;
        background: #0061f2 url(~@/assets/right-0@2x.png) no-repeat center center;
        background-size: 18px 18px;
      }
      .wrong {
        cursor: pointer;
        text-align: center;
        margin-right: 10px;
        width: 23px;
        height: 23px;
        border-radius: 4px;
        font-size: 11px;
        font-weight: 500;
        line-height: 23px;
        color: #45484d;
        margin-bottom: 8px;
        background: #45484d url(~@/assets/wrong.png) no-repeat center center;
        background-size: 18px 18px;
      }
      .wrong-active {
        cursor: pointer;
        text-align: center;
        margin-right: 10px;
        width: 23px;
        height: 23px;
        border-radius: 4px;
        font-size: 11px;
        font-weight: 500;
        line-height: 23px;
        color: #45484d;
        margin-bottom: 8px;
        background: #0061f2 url(~@/assets/wrong.png) no-repeat center center;
        background-size: 18px 18px;
      }
      .o-choose {
        cursor: pointer;
        text-align: center;
        margin-right: 10px;
        width: 23px;
        height: 23px;
        background: #0061f2;
        border-radius: 4px;
        font-size: 11px;
        font-weight: 500;
        line-height: 23px;
        color: #ffffff;
        margin-bottom: 8px;
      }
      .add {
        font-size: bold;
      }
      .minus {
        font-size: bold;
      }
    }
    .c-title {
      height: 53px;
      font-size: 11px;
      font-family: SF Pro Text;
      font-weight: 500;
      line-height: 53px;
      color: #17181a;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      .blue-text {
        font-size: 14px;
        font-family: SF Pro Text;
        font-weight: 500;
        color: #0061f2;
      }
    }
    .c-detail {
      display: flex;
      flex-direction: row;
      .c-title {
        border: none;
        padding-right: 15px;
      }
    }
    .c-list {
      .c-item {
        padding-top: 22px;
        span {
          height: 15px;
          font-size: 11px;
          font-family: SF Pro Text;
          font-weight: 500;
          line-height: 15px;
          color: #2e3033;
          opacity: 1;
          padding-right: 44px;
        }
      }
    }
  }
  .wrap-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 7px;
    border-top: 1px solid #dcdcde;
    .subBtn {
      width: 141px;
      height: 23px;
      background: #0069f2;
      border-radius: 4px;
      padding: unset;
      font-size: 9px;
      font-family: SF Pro Text;
      font-weight: bold;
      line-height: 23px;
      color: #ffffff;
      border: none;
    }
    .cancleBtn {
      width: 141px;
      height: 23px;
      background: #ffffff;
      border-radius: 4px;
      padding: unset;
      font-size: 9px;
      font-family: SF Pro Text;
      font-weight: bold;
      line-height: 23px;
      color: #7e848c;
      border: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
  .c-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    line-height: 48px;
    background: #edeff2;
    .c-title {
      padding-left: 10px;
      height: 26px;
      font-size: 16px;
      font-family: SF Pro Text;
      line-height: 26px;
      color: #000000;
    }
    .c-btn {
      display: flex;
      flex-direction: row;
      align-items: center;
      .c-uploader {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .c-upload {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 95px;
        height: 31px;
        background: #ffffff;
        border: 1px solid #4378be;
        opacity: 1;
        border-radius: 3px;
        font-size: 12px;
        font-family: SF Pro Text;
        font-weight: 500;
        line-height: 31px;
        color: #4378be;
        margin-right: 10px;
        .icon-upload {
          padding-right: 5px;
          display: inline-block;
          width: 13.6px;
          height: 17px;
          background: url(~@/assets/upload.png) no-repeat center center;
          background-size: 13.6px 17px;
        }
      }
    }
  }
}
.ask-dropdown {
  padding: unset;
  width: 158px;
  height: 242px;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  .l-header {
    box-sizing: border-box;
    width: 158px;
    height: 28px;
    background: #f5f5f7;
    border-radius: 8px 8px 0px 0px;
    font-size: 9px;
    font-family: SF Pro Text;
    font-weight: bold;
    line-height: 28px;
    color: #45494d;
    padding-left: 15px;
    padding-right: 15px;
  }
  .i-list {
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-right: 17px;
    .i-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 11px;
      .i-name {
        width: 87px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 15px;
        font-size: 11px;
        font-family: SF Pro Text;
        font-weight: 500;
        line-height: 15px;
        color: #2e3033;
      }
      .right {
        display: flex;
        flex-direction: row;
        .phone {
          width: 11px;
          height: 11px;
          background: url(~@/assets/phone@2x.png) no-repeat;
          background-size: 11px 11px;
          margin-right: 15px;
        }
        .leave {
          width: 11px;
          height: 11px;
          background: url(~@/assets/leave@2x.png) no-repeat;
          background-size: 11px 11px;
        }
      }
    }
  }
}
.micro-dropdown {
  width: 125px;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 8px;
  .text {
    cursor: pointer;
    box-sizing: border-box;
    display: inline-block;
    padding-left: 8px;
    width: 125px;
    height: 15px;
    font-size: 11px;
    font-family: SF Pro Text;
    font-weight: 500;
    line-height: 15px;
    color: #2e3033;
  }
}
.dropdown-content {
  width: 234px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
  .i-hollow {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 34px;
    line-height: 34px;
    .rectangle {
      margin-left: 24px;
      width: 18px;
      height: 34px;
      line-height: 34px;
      background: url(~@/assets/rectangle-hollow@2x.png) no-repeat center center;
      background-size: 18px 18px;
    }
    .rectangle-active {
      margin-left: 24px;
      width: 18px;
      height: 34px;
      line-height: 34px;
      background: url(~@/assets/rectangle-hollow-active.png) no-repeat center center;
      background-size: 18px 18px;
    }
    .oval-active {
      margin-left: 24px;
      width: 18px;
      height: 34px;
      line-height: 34px;
      background: url(~@/assets/oval-hollow-active.png) no-repeat center center;
      background-size: 18px 18px;
    }
    .oval {
      margin-left: 24px;
      width: 18px;
      height: 34px;
      line-height: 34px;
      background: url(~@/assets/oval-hollow@2x.png) no-repeat center center;
      background-size: 18px 18px;
    }
    .square {
      margin-left: 24px;
      width: 18px;
      height: 34px;
      line-height: 34px;
      background: url(~@/assets/square-hollow@2x.png) no-repeat center center;
      background-size: 18px 18px;
    }
    .square-active {
      margin-left: 24px;
      width: 18px;
      height: 34px;
      line-height: 34px;
      background: url(~@/assets/square-hollow-active.png) no-repeat center center;
      background-size: 18px 18px;
    }
    .circle {
      margin-left: 24px;
      width: 18px;
      height: 34px;
      line-height: 34px;
      background: url(~@/assets/circle-hollow@2x.png) no-repeat center center;
      background-size: 18px 18px;
    }
    .circle-active {
      margin-left: 24px;
      width: 18px;
      height: 34px;
      line-height: 34px;
      background: url(~@/assets/circle-hollow-active.png) no-repeat center center;
      background-size: 18px 18px;
    }
  }
  .i-solid {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 34px;
    line-height: 34px;
    .rectangle {
      margin-left: 24px;
      width: 18px;
      height: 34px;
      line-height: 34px;
      background: url(~@/assets/rectangle-solid@2x.png) no-repeat center center;
      background-size: 18px 18px;
    }
    .rectangle-active {
      margin-left: 24px;
      width: 18px;
      height: 34px;
      line-height: 34px;
      background: url(~@/assets/rectangle-solid-active.png) no-repeat center center;
      background-size: 18px 18px;
    }
    .oval-active {
      margin-left: 24px;
      width: 18px;
      height: 34px;
      line-height: 34px;
      background: url(~@/assets/oval-solid-active.png) no-repeat center center;
      background-size: 18px 18px;
    }
    .oval {
      margin-left: 24px;
      width: 18px;
      height: 34px;
      line-height: 34px;
      background: url(~@/assets/oval-solid@2x.png) no-repeat center center;
      background-size: 18px 18px;
    }
    .square {
      margin-left: 24px;
      width: 18px;
      height: 34px;
      line-height: 34px;
      background: url(~@/assets/square-solid@2x.png) no-repeat center center;
      background-size: 18px 18px;
    }
    .square-active {
      margin-left: 24px;
      width: 18px;
      height: 34px;
      line-height: 34px;
      background: url(~@/assets/square-solid-active.png) no-repeat center center;
      background-size: 18px 18px;
    }
    .circle {
      margin-left: 24px;
      width: 18px;
      height: 34px;
      line-height: 34px;
      background: url(~@/assets/circle-solid@2x.png) no-repeat center center;
      background-size: 18px 18px;
    }
    .circle-active {
      margin-left: 24px;
      width: 18px;
      height: 34px;
      line-height: 34px;
      background: url(~@/assets/circle-solid-active.png) no-repeat center center;
      background-size: 18px 18px;
    }
  }
  .i-style {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 38px;
    line-height: 38px;
    .font-size {
      display: flex;
      flex-direction: row;
      align-items: center;
      .current-size {
        color: #0069f2;
      }
      .small {
        font-size: 9px;
      }
      .mid {
        font-size: 12px;
      }
      .big {
        font-size: 15px;
      }
      .max {
        font-size: 18px;
      }
      span {
        text-align: center;
        display: inline-block;
        cursor: pointer;
        margin-left: 31px;
        width: 18px;
        height: 25px;
        line-height: 25px;
        font-weight: 500;
        color: #000000;
      }
    }
    .color-block {
      width: 14px;
      height: 14px;
      opacity: 1;
      border-radius: 4px;
      margin: 3px 3px 3px 8px;
    }
    .current-color {
      padding: 2px;
      width: 16px;
      height: 16px;
      margin: 3px 3px 3px 8px;
      border: 2px solid #0069f2;
    }
    .color-block:nth-child(1) {
      background: #ff0000;
    }
    .color-block:nth-child(2) {
      background: #000000;
    }
    .color-block:nth-child(3) {
      background: #949699;
    }
    .color-block:nth-child(4) {
      background: #ff6a0c;
    }
    .color-block:nth-child(5) {
      background: #f7b502;
    }
    .color-block:nth-child(6) {
      background: #02bf50;
    }
    .color-block:nth-child(7) {
      background: #43d7b5;
    }
    .color-block:nth-child(8) {
      background: #0069f2;
    }
    .color-block:nth-child(9) {
      background: #6336ff;
    }
    .pen-curve {
      margin-left: 26px;
      width: 22px;
      height: 38px;
      line-height: 38px;
      background: url(~@/assets/curve@2x.png) no-repeat center center;
      background-size: 22px 22px;
    }
    .pen-curve-active {
      background: url(~@/assets/curve-active.png) no-repeat center center;
      background-size: 22px 22px;
    }
    .line {
      margin-left: 26px;
      width: 22px;
      height: 38px;
      line-height: 38px;
      background: url(~@/assets/line@2x.png) no-repeat center center;
      background-size: 18px 18px;
    }
    .line-active {
      background: url(~@/assets/line-active.png) no-repeat center center;
      background-size: 18px 18px;
    }
    .slider {
      width: 214px;
      line-height: 38px;
    }
  }
}
.chat-room {
  height: 483px;
  background: #ffffff;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-radius: 8px;
  .header {
    height: 23px;
    background: #f5f5f7;
    text-align: center;
    font-size: 9px;
    font-weight: 500;
    line-height: 23px;
    color: #45484d;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  .content {
    box-sizing: border-box;
    padding: 10px 10px;
    width: 415px;
    height: 321px;
    margin-top: 2px;
    padding: 7px;
    overflow-y: auto;
    margin-bottom: 10px;
    .c-wrap {
      box-sizing: border-box;
      width: 390px;
      margin-bottom: 8px;
      overflow: hidden;
    }
    .c-wrap {
      .c-other {
        float: left;
        .c-content {
          display: flex;
          flex-direction: row;
          align-items: center;
          .avatar {
            width: 25px;
            height: 25px;
            margin-right: 5px;
            border-radius: 4px;
            background: no-repeat center center;
            background-size: cover;
          }
          .text {
            margin-left: 6px;
            width: 180px;
            padding: 6px 5px;
            background: #f2f3f3;
            border-radius: 4px;
            font-size: 9px;
            font-weight: 500;
            line-height: 13px;
            color: #000000;
          }
        }
        .name {
          height: 13px;
          font-size: 9px;
          font-weight: 500;
          line-height: 13px;
          color: #94959a;
          margin-bottom: 4px;
        }
      }
    }
    .c-wrap {
      .c-me {
        float: right;
        .c-content {
          display: flex;
          flex-direction: row;
          align-items: center;
          .avatar {
            width: 25px;
            height: 25px;
            border-radius: 4px;
            margin-right: 5px;
            background: no-repeat center center;
            background-size: cover;
          }
          .text {
            margin-right: 6px;
            width: 180px;
            padding: 6px 5px;
            background: #e5f0fe;
            border-radius: 4px;
            font-size: 9px;
            font-weight: 500;
            line-height: 13px;
            color: #000000;
          }
        }
        .name {
          margin-right: 5px;
          text-align: right;
          height: 13px;
          font-size: 9px;
          font-weight: 500;
          line-height: 13px;
          color: #94959a;
          margin-bottom: 4px;
        }
      }
    }
  }
  .reply {
    width: 415px;
    height: 139px;
    .r-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 3px 8px;
      background-color: #ffffff;
      .r-title {
        font-size: 9px;
        font-family: SF Pro Text;
        font-weight: 500;
        color: #2e3033;
      }
      .r-text {
        padding-right: 7px;
        height: 20px;
        line-height: 20px;
        font-size: 9px;
        font-family: SF Pro Text;
        font-weight: 500;
        color: #7e848c;
      }
    }
    .textareaInput textarea {
      padding: 7px;
      height: 65px;
      font-size: 9px;
      font-weight: bold;
      line-height: 13px;
      color: #000000;
      border: none;
    }
    .el-textarea__inner {
      //el_input中的隐藏属性
      resize: none; //主要是这个样式
      margin: unset;
    }
    .r-wrap {
      float: right;
      .r-btn {
        margin: 7.5px;
        padding: unset;
        width: 57px;
        height: 27px;
        background: #edeff2;
        border-radius: 6px;
        font-size: 11px;
        font-family: SF Pro Text;
        font-weight: bold;
        line-height: 12px;
        color: #5c6066;
      }
    }
  }
}
</style>
